import { Link, useHistory } from 'react-router-dom';

const AboutRealSplit = () => {
    return (
        <>

        <h1>
            About RealSplit
        </h1>

        <p>
            RealSplit™ is a private company that offers individuals and organizations the opportunity to invest into high value income-producing commercial real estate properties through a unique fractional ownership structure. 
        </p>
        <p>
            RealSplit’s proprietary software platform is the mechanism used to facilitate the buying and selling of these investment opportunities. The platform has been integrated with a licensed broker-dealer, who seamlessly handles all the legal and compliance aspects.
        </p>
        <p>
            We aim to provide accessible and diversified investment opportunities for those seeking to participate in the commercial real estate market without the burdens of sole ownership. RealSplit will monetize the assets that we acquire and will distribute the cash flow earnings from those properties in the form of dividends.
        </p>

        <div
            className = 'vision-mission'
            //style = {{
            //    backgroundColor: 'var(--color-theme-primary)'
            //    , borderRadius: '8px'
            //    , color: '#fff'
            //    , margin: '15px 50px'
            //    , border: 'var(--color-gray-300) 1px solid'
            //}}
            >
            <h3>
                Vision
            </h3>
            <p>
                {/*
                To democratize commercial real estate investment by enabling individual investors to benefit from fractional ownership of premium income producing assets.
                */}
                Empowering individual investors by unlocking access to premium income producting commercial real estate through fractional ownership.
            </p>
        </div>

        <div
            className = 'vision-mission'
            //style = {{
            //    margin: '15px 50px'
            //    , border: 'var(--color-gray-300) 1px solid'
            //}}
            >
            <h3>
                Mission
            </h3>
            <p>
                To provide transparent, secure, and profitable fractional real estate ownership opportunities to investors by utilizing a proprietary technology platform to enable operational efficiencies and an exceptional customer experience.
            </p>
        </div>

        <p>
            We are providing investors an opportunity to invest into Commercial Real Estate (CRE) deals that may not normally be available to them given the industry’s high barriers to entry, which typically include significant upfront capital requirements.
        </p>
        <p>
            By creating a platform which facilitates the fractionalized (split) ownership of commercial real estate assets we can reduce the minimum individual investment amount while also providing a robust and exclusive secondary marketplace for our investment community.
        </p>

        <h2>
            How it Works
        </h2>
        <div>
            <ul>
                <li>
                    RealSplit will identify Commercial Real Estate properties that have been vetted (by us) and meet our minimum investment criteria.
                </li>
                <li>
                    Our underwriting team, led by RealSplit co-founder Brian Massie, will do an initial due diligence investigation specific to the property type considering variables such as location, size, ownership structure, financing, title, physical condition and return on investment.
                </li>
                <li>
                    RealSplit will select properties that have passed initial due diligence and place those under contract by posting a refundable earnest money deposit typically in the amount of 1% - 3% of the purchase price. By doing so the property will be under RealSplit’s control for a certain period of time during which we will perform extensive due diligence.
                </li>
                <li>
                    We will fractionalize the property into Splits, each of which represents a percentage ownership in the LLC. The number of splits offered will equal the total amount of investment needed to purchase the property.
                </li>
                <li>
                    Properties that pass our formal due diligence process will be listed on the RealSplit platform and made available for members to invest in.
                </li>
                <li>
                    Each property that RealSplit invests in will be 100% owned by a Limited Liability Company (LLC) which RealSplit manages, and investors will be members of.
                </li>
            </ul>
        </div>

        <h2>
            What Makes RealSplit Unique
        </h2>

        <h3>
            Fractionalized Ownership of Existing Properties
        </h3>
        <p>
            You get real ownership and have an interest in the property!
        </p>

        <h3>
            Lower Barrier to Entry to Income-Producing Commercial Real Estate
        </h3>
        <p>
            By fractionalizing the ownership, we can offer a “minimum investment amount” per property that is significantly lower than a traditional investment syndication. Most retail investors would not normally have access to, or even afford the minimum investment in a traditional commercial real estate deal.
        </p>

        <h3>
            Lower Burden of Ownership
        </h3>
        <p>
            Splitting the total investment amongst other investors reduces your risk.
        </p>

        <h3>
            Passive Income
        </h3>
        <p>
            A great way to generate income and not have any of the responsibilities of running or managing the property.
        </p>

        <h3>
            Control How You Want to Invest / Flexible Investment Strategies
        </h3>
        <p>
            Choose whether you prefer to select individual properties to invest into or invest into a fund managed by RealSplit and let the experts choose for you.
        </p>

        <h3>
            Highly Vetted Properties
        </h3>
        <p>
            RealSplit performs thorough due diligence on all properties which will be available to investors for their review. All properties will be required to meet minimum thresholds including physical (location, condition, size, etc.) and financial.
        </p>

        <h3>
            Perform Due Diligence using Artificial Intelligence (AI)
        </h3>
        <p>
            We are utilizing an innovative AI that has undergone deep learning and has received extensive training on our due diligence methodology. AI will do all the heavy lifting so our human Analysts can focus their time on Vetting the best deals. This will save RealSplit significant time and money and enable us to scale rapidly.
        </p>

        <h3>
            Offering Ownership in Properties that are Not for Sale
        </h3>
        <p>
            Given the slump in the real estate market, property owners are facing loans that are maturing that they will have a hard time refinancing. RealSplit will offer them a unique opportunity to sell a fraction of their property, something not available in other real estate investing platforms.
        </p>

        <h3>
            Secondary Market Trading Platform
        </h3>
        <p>
            In a word, Liquidity! RealSplit has created a proprietary trading platform which allows investors to buy and sell their ownership interest once the SEC mandated holding period is over. This makes selling some or all of your ownership interest significantly easier than traditional real estate investing where your capital is typically locked in a long-term hold period. You have the flexibility to liquidate your investment at your descrepancy.
        </p>

        <h3>
            Income Producing Properties
        </h3>
        <p>
            We are investing in income producing properties. Investors will receive their profits from these properties in the form of quarterly dividends.
        </p>

        <h3>
            Refinancing for Investor Returns
        </h3>
        <p>
            As interest rates come down from highs that have not been seen since the 1980’s, RealSplit will opportunistically refinance performing assets and redeploy that capital back to investors.
        </p>

        {/*
        */}
        <h2>
            Business Model
        </h2>
        <p style = {{ overflow: 'auto' }}>
            <img src="/images/about/Capture-Business-Plan-Business-Model-Flow.png" style = {{ width: '100%', minWidth: '500px' }}/>
        </p>

        <h2>
            Service Offerings
        </h2>

        <h3>
            Fractional Ownership
        </h3>
        <p>
            Offering ownership interest (splits) of carefully selected income-producing commercial real estate properties to our investment community. RealSplit will pursue various asset classes including multi- family, self-storage, senior housing, mixed-use, industrial and hospitality.
        </p>

        <h3>
            Property Selection
        </h3>
        <p>
            RealSplit prioritizes properties located in high barrier to entry markets with favorable demographics and strong long-term growth potential.
        </p>

        <h3>
            Property Management
        </h3>
        <p>
            Providing efficient, comprehensive, and technologically advanced management solutions to ensure the upkeep and profitability of the properties we own and operate.
        </p>

        <h3>
            Secondary Exchange
        </h3>
        <p>
            RealSplit provides a secondary marketplace where investors may buy or sell ownership interest in properties that have been listed by other community members for sale.
        </p>

        <h3>
            Property Maintenance
        </h3>
        <p>
            On-site maintenance staff will perform all basic property maintenance and will be complemented by contractors and handyman with construction, rehabilitation, and renovation experience.
        </p>

        <h3>
            Construction And Renovation
        </h3>
        <p>
            The RealSplit management team will be directly involved in overseeing all aspects of construction and renovation of existing and newly acquired properties. The company will hire a general contractor to oversee each project.  
        </p>

        <h3>
            Asset Management
        </h3>

        <p>
            RealSplit will rely on the size, depth, and skill of third-party management companies to manage the day-to-day operational issues of each property. However, unlike many investment companies, RealSplit’s involvement will not end at the closing table.
            RealSplit continues to apply owner's scrutiny with constant focus on the asset’s operation in order to ensure the property is reaching its full potential and maximizing performance returns. There is no substitute for Management’s vigorous oversight of investment properties, especially given the founder’s wealth of real estate industry experience. Typical activities performed by RealSplit Inc. in the process of real estate asset management include:
        </p>

        <div>
            <ul>
                <li>
                    Review and critique all financial statements as generated to ensure property is operating as effectively and efficiently as possible.
                </li>
                <li>
                    Encourage the management company to enhance revenues and profits by challenging them to devise better ways of managing.
                </li>
                <li>
                    Suggest alternative yield management strategies.
                </li>
                <li>
                    Manage expenditures to achieve operational efficiencies and improve margins.
                </li>
                <li>
                    Evaluate human resources to ensure management company has the best possible people managing the property.
                </li>
                <li>
                    Complete physical inspections of property and address repairs and maintenance issues promptly.
                </li>
                <li>
                    Visit a property's market area frequently to assess any changing conditions that may affect performance.
                </li>
            </ul>
        </div>

        <p>
            RealSplit will establish and implement policies, procedures, and a chain of command throughout the organization that effectively and efficiently oversees daily operations while maintaining a competitive- edge. RealSplit Asset Management will focus on critical areas of responsibility for each property acquired, including Accurate income/expense management and reporting; Capital improvements; Preventative maintenance programs; Value enhancement; Marketing and advertising; Tenant services; and building positive community relationships. 
        </p>

        <h2>
            Property Selection
        </h2>
        <p>
            RealSplit conducts comprehensive analysis of the current real estate market, including trends, demographics, market dynamics, financial conditions, and potential competition. RealSplit produces due diligence reports for each property which include detailed descriptions, proforma financials and anticipated return on investment. RealSplit produces quarterly reports for our investment community that we make available to members.
        </p>

        <h3>
            Minimum Investment Criteria
        </h3>
        <p>
            All potential deals must meet certain minimum investment criteria prior to being offered on the RealSplit Platform.
        </p>

        <h3>
            Detailed Due Diligence
        </h3>
        <p>
            RealSplit follows a detailed due diligence checklist which includes a review of all physical and financial information specific to each deal.
        </p>

        <h3>
            "Location Location Location"
        </h3>
        <p>
            RealSplit prioritizes properties located in high barrier to entry markets with favorable demographics and strong long-term growth potential.
        </p>

        <h3>
            ROI Hurdles
        </h3>
        <p>
            Properties must meet specific forward-looking ROI hurdles which vary by asset class and location.
        </p>

        <h3>
            Prioritize Fractionalization
        </h3>
        <p>
            Only properties which are deemed suitable for fractionalization will be considered for the RealSplit Platform. This test includes location, cost, ownership structure, encumbrances, physical condition, management, cashflow, and value-add potential.
        </p>

        <h3>
            ROI and Financial Reports
        </h3>
        <p>
            RealSplit produces due diligence reports for each property which include pro forma financials and anticipated return on investment.
        </p>


        {/*
        <h2>
            Value Proposition
        </h2>
        <p>
            RealSplit’s lean organization structure, along with the in-depth industry knowledge and wide experience of its managing partners will enable the company to offer a distinct value proposition to investors, property sellers, tenants, and buyers.
        </p>

        <h3>
            For Investors
        </h3>
        <p>
            RealSplit will offer a sound and rewarding investment vehicle to investors looking for promising opportunities in commercial real estate. The investments will be undertaken after careful analysis and evaluation to provide investors with outstanding returns coupled with the highest degree of service standards. The managing partners’ in-depth knowledge of the real estate industry and wide experience will provide RealSplit with the vision and skills needed to develop winning investment strategies. Regular dividend incomes and the prospect of high capital appreciation will attract investors to RealSplit’s service offerings. Investors will also benefit from cost savings on account of economies of scale, due to the proposed large volume and high value of deals executed by the company.
        </p>

        <h3>
            For Sellers
        </h3>
        <p>
            RealSplit will have a leaner organization structure when compared to other real-estate investment firms, making it nimbler in its operations. This, coupled with solid capitalization, will enable the company to strike and execute deals quickly. The company will retain strong cash reserves which will enable it to achieve certainty and speed in deal execution. This will benefit both RealSplit and third-party property sellers by facilitating quick, hassle-free transactions.
        </p>
        */}

        <h2>
            Why RealSplit
        </h2>

        <h3>
            Deal Access
        </h3>
        <p>
            We offer access to properties that your average investor would not typically have. All investment opportunities are commercial real estate assets with purchase prices ranging from $10 to $500 million.
        </p>

        <h3>
            User-friendly Platform
        </h3>
        <p>
            <img src="/images/about/RealSplit-User-Friendly-Find-Deals-1473x1107.jpg" style = {{ width: '100%' }}/>
        </p>

        <p>
            RealSplit™ offers a turn-key solution for fractionalized commercial real estate ownership to the average investor by creating a user-friendly platform to research, buy and sell a variety of income-producing properties.
        </p>

        <h3>
            Multiple Services
        </h3>
        <p>
            RealSplit offers its investment community a multitude of services which are not typically found on other platforms including transparent due diligence, professional asset management, construction management, asset utilization and financing solutions.
        </p>

        <h3>
            Modern Software Stack
        </h3>
        <p>
            {/*
            RealSplit uses a modern software technology stack that encompasses tools like React and NodeJS for front-end user interface development, tools like AWS, PostgreSQL, and MongoDB for back-end services, DLT (Distributed Ledger Technology) for digital asset management, along with GitLab and Docker for automated Dev-Ops and CI/CD (Continuous Integration / Continuous Delivery) of updates to the software using modern source code management techniques and infrastructure.
            */}
            RealSplit uses a modern software technology stack with tools like React and NodeJS for front-end development, and AWS, Docker, PostgreSQL, MongoDB, and GitLab for back-end.
            We use Distributed Ledger Technology for asset management and have integrated a broker/dealer for secure, compliant asset registration.
            {/*
            GitLab is used for source code management along with AWS for hosting, automated dev-ops and continuous delivery of updates to the software.
            Docker and Jenkins are used for building and deploying the back-end applications.
            */}
        </p>

        <h3>
            Blockchain and AI
        </h3>
        <p>
            RealSplit is one of the first fractionalized commercial real estate investment platforms to effectively use both blockchain technology and AI to facilitate a secure, efficient, transparent, and streamlined user experience.
        </p>

        <h3>
            Positioning
        </h3>
        <p>
            RealSplit will position itself as a lean, professionally managed real estate investment company, promoted by industry experts, offering highly rewarding opportunities for investors looking for steady income streams, healthy capital appreciation, and an effective hedge against inflation.
        </p>

        <h3>
            Tax Benefits
        </h3>
        <p>
            Commercial real estate investing can provide a variety of tax benefits to investors. These benefits include things like accelerated depreciation and mortgage interest deduction. These can lead to a significant difference in investment returns, especially over an extended period of time. Depreciation will reduce the amount of taxes investors will have to pay. Please note that RealSplit does not provide tax advice, and we encourage all investors to work with a qualified tax advisor when making investment decisions.
        </p>

        <h3>
            Victoria AI
        </h3>
        <p
            style = {{
                display: 'flex'
                , alignItems: 'flex-start'
                , flexWrap: 'wrap'
                , flexDirection: 'row'
                , justifyContent: 'space-around'
                , gap: '20px'
                , rowGap: '20px'
            }}
            >
            <div
                style = {{
                    //flexGrow: '0'
                    flexBasis: '200px'
                }}>
                <img
                    //src="/images/about/realsplit-victoria-jancke.jpg"
                    src="https://realsplit-s3-bucket-us-east-1.s3.amazonaws.com/Victoria-Circle.jpg"
                    style = {{
                        //width: '120px'
                        width: '100%'
                        , maxWidth: '200px'
                    }}
                    />
            </div>
            <div
                style = {{
                    flexBasis: '50%'
                    , flexGrow: '1'
                }}
                >
                <span
                    //style = {{ marginLeft: '20px'}}
                    >
                    RealSplit uses an Artificial Intelligence (AI) Assistant, <Link to="/about/victoria-ai">Victoria AI</Link>. Victoria is a real-life model who has recreated her likeness as an AI Assistant. She has been trained using our entire business model, and will be continuously learning more. Please ask Victoria your questions about RealSplit.
                </span>
            </div>
        </p>

        <h3>
            The Perfect Storm
        </h3>
        <p>
            <img src="/images/about/realsplit-the-pefect-storm.png" style = {{ width: '100%' }}/>
        </p>

        <div>
            <ul>
                <li>
                    The commercial real estate industry is currently under a great deal of stress following a global pandemic, political turmoil, hyper-inflation, non-payment of rent and extreme interest rate increases within a short period of time. These factors have contributed to increased vacancy loss, higher operating expenses, bad debt, and lower Net Operating Income. This in turn is negatively impacting valuations, making it more challenging for owners and operators to produce positive cashflow, meet debt coverage ratios and secure financing.
                </li>
                <li>
                    There is an increasing rate of commercial real estate loan defaults.
                </li>
                <li>
                    Many commercial property owners and mortgage holders may find themselves with no choice but to refinance at unfavorable terms, sell their assets at deep discount or have a lender foreclose on their property. Additionally, there will be commercial loans maturing with limited financing options available, and individual owners such as non-depository financial institutions and over-leveraged investors will face rising interest rates on properties with declining revenues.
                </li>
                <li>
                    This creates buying opportunities for well-capitalized platforms like RealSplit to directly acquire distressed properties and non-performing notes, as well as fractionalize properties which will provide a more favorable option and needed liquidity to owners.
                </li>
            </ul>
        </div>

        </>
    );
};
  
export default AboutRealSplit;