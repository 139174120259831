console.log('/utils/decrypt.js');

const CryptoJS = require('crypto-js');
console.log('/utils/decrypt.js: CryptoJS = ', CryptoJS);
console.log('/utils/decrypt.js: process.env.REACT_APP_SIGN_MESSAGE = ', process.env.REACT_APP_SIGN_MESSAGE);

let iv, key;

if (process.env.REACT_APP_SIGN_MESSAGE) {

    iv = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SIGN_MESSAGE);
    console.log('/utils/decrypt.js: iv = ', iv);
    key = CryptoJS.SHA256(process.env.REACT_APP_CRYPTO_KEY);
    console.log('/utils/decrypt.js: key = ', key);

} else {
    console.warn('Missing env variable: process.env.REACT_APP_SIGN_MESSAGE');
}


// const iv = crypto.AES.encrypt(JSON.stringify({ str: process.env.REACT_APP_SIGN_MESSAGE }), 'secret').toString();
// export const encryptData=(data)=>{
// 	 	     if(typeof data=="string"){
//             data=data.slice();
//           encryptedString = CryptoJS.AES.encrypt(data, key, {
// 	          iv: iv,
// 	          mode: CryptoJS.mode.CBC,
// 	          padding: CryptoJS.pad.Pkcs7
// 	    });
//           }
// 	       else{
//          encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
// 	          iv: iv,
// 	          mode: CryptoJS.mode.CBC,
// 	          padding: CryptoJS.pad.Pkcs7
// 	    });
//          }
// 	    return encryptedString.toString();
// }

export const decryptData = encrypted => {
    console.log('decryptData('+encrypted+')');
    
    if (encrypted) {

        if (iv && key) {

            var decrypted = CryptoJS.DES.decrypt(
                encrypted
                , key
                , {
                      iv: iv
                    , mode: CryptoJS.mode.CBC
                    , padding: CryptoJS.pad.Pkcs7
                }
            );
            console.log('decrypted = ', decrypted);

            // console.log(iv, key, decrypted.toString(CryptoJS.enc.Utf8), "check")
            try {
                console.log('decrypted.toString('+CryptoJS.enc.Utf8+') = ', decrypted.toString(CryptoJS.enc.Utf8));
                return decrypted.toString(CryptoJS.enc.Utf8);
            } catch(error) {
                console.log('error = ', error);
            }
            return null;
        }
    }
};
