import
    React
    , {
          useRef
        , useState
        , useCallback
        , useEffect
    } from "react";

import {
      BrowserRouter as Router
    , Switch
    , Route
    , useHistory
    , useLocation
    //, useParams
    //, withRouter
    , Link
} from 'react-router-dom';

import { useDispatch }        from 'react-redux';
import { useSelector }        from 'react-redux';
//import { persistStore }                 from 'redux-persist';
import { MetaKeep }                     from 'metakeep';
//import store                            from "./redux/store";
import { referralCodeUpdate }           from "./redux/keySlice";
import {
    details
    , increment
    //, setEuroValue
} from './redux/counterSlice';
import {
    axiosInstance
    //, axiosInstanceBlockChain
} from "./utils/API";
import "./styles/app.sass";

import ModalDialogWrapper               from "./screens/ModalDialogWrapper";
import ResetPassword                    from "./components/ResetPassword";
//import MobileNav                        from './components/Header/MobileNav';
import Page                      	   	from "./components/Page";
//import Home                      	   	from "./screens/Home";
import HomePage                  	   	from "./components/HomePage";
import UploadVariants            	   	from "./screens/UploadVariants";
import UploadDetails             	   	from "./screens/UploadDetails";
import ConnectWallet             	   	from "./screens/ConnectWallet";
//import Faq                       	   	from "./screens/Faq";
import About                     	   	from "./screens/About";
import VictoriaAI                       from "./screens/About/VictoriaAI";
//import TermsAndConditions        	   	from "./screens/TermsAndConditions/TermsAndConditions"
import TermsAndConditions               from "./screens/TermsAndConditions"
//import PrivacyPolicy             	   	from "./screens/PrivacyPolicy/privacyPolicy"
import PrivacyPolicy                    from "./screens/PrivacyPolicy"
import ShippingPolicy                   from "./screens/ShippingPolicy/ShippingPolicy";
import ReturnsPolicy                    from "./screens/ReturnsPolicy/ReturnsPolicy";
import Activity                  	   	from "./screens/Activity";
import Search                    	   	from "./screens/Search"; // ViewAssets renamed to Search
//import Search02                  	   	from "./screens/Search02";
import Profile                   	   	from "./screens/Profile";
import ProfileEdit               	   	from "./screens/ProfileEdit";
import Item                      	   	from "./screens/Item";
import ItemAcceptance                   from "./screens/ItemAcceptance";
import Checkout                  	   	from "./screens/Checkout"
import PageList                  	   	from "./screens/PageList";
import MyAssets                  	   	from "./screens/MyAssets"; // UserItem renamed to MyAssets
//import CheckoutReceipt           	   	from "./screens/userItem/CheckoutReceipt";
import ResellForm                	   	from './components/Resellform';
import EditProfile                      from './screens/EditProfile';
import UserProfile                      from './screens/UserProfile';
//import Registration                     from './screens/Registration';
import OrderDetails                     from "./screens/OrderDetails";
import OrderDetail                      from "./screens/OrderDetail";
//import Landing                          from './components/landingpage/landingpage';
import Web3signature                    from "./components/Signature/Web3signnature";
//import ResellItem                       from './screens/userItem/ResellItem'; // ResellItem renamed to ResellAssets
import ResellAssets                     from './screens/ResellAssets';
//import Counter                          from "./screens/counter/Counter";
import Auctions                          from './screens/Auctions';
//import ThreeDee                         from "./screens/Threedee/ThreeDee";
// import { widgetMatic }                  from './controller/utils';
//import ThreeDeeViewer                   from "./components/ThreeDeeViewer/ThreeDeeViewer";
import Faqs                             from "./screens/Faqs/Faqs";
//import ContactUs                        from "./screens/Home/ContactUs";
import ContactUs                        from "./screens/ContactUs";
import FailPayment                      from './components/Failpayment/FailPayment';
//import { GoogleReCaptchaProvider }      from 'react-google-recaptcha-v3';
import ReferralLink                     from "./screens/ReferralLink"; // ReferAndEarn renamed to ReferralLink
import ReferredAffiliates               from "./screens/ReferredAffiliates";
import RewardHistory                    from "./screens/RewardHistory";
import MyWallet                         from "./screens/MyWallet";
//import Responsive                       from "./screens/Responsive"; // for development
import CartCheckout                     from "./screens/CartCheckout";
import Cart                             from "./screens/Cart";
import Swal                             from "sweetalert2";  // SweetAlert for modal dialogs
import Modal                            from "./components/Modal";
import LogLevels                        from './utils/LogLevels';

//import { CircularProgress } from "@material-ui/core";
import {
    CircularProgress
    //, Drawer
    //, Divider
    //, IconButton
    //, TextField
} from "@mui/material";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { NAMES_CONSTANTS } from './constants';

// Web3 Wallet Connect
import Web3Modal from "web3modal";
import Web3 from "web3";
//import WalletConnectProvider from "@walletconnect/web3-provider";
import { decryptData } from "./utils/decrypt";

import {
      base_url
    //, contract_auction_abi
    //, contract_erc20token_abi
    //, widgetMatic
    //, Web3Provider
    //, getProvider
    , checkNetwork
    , connector
    , getBalanceToken
    , PopUpAlert
    , PopUpWarning
    , PopUpWarningThen
    , PopUpConfirmation
} from "./controller/utils";

import LoginModal from "./components/Header/LoginModal";
import Notifications from "./screens/Notifications";

import { setUser } from "./redux/userSlice";
import { decrement } from './redux/counterSlice';
import { countCart } from './redux/cartSlice';
  
let currency = 'MATIC';
//let persistor = persistStore(store);

let itemId;

function App() {
    console.info('App()');
    //console.log('App()');
    
    const fun = () => {
        alert('fun!');
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //let getWindowWidth = () => {
        //return innerWidth;
    //    return windowWidth;
    //};

    // Set the z-index on Victoria so that popup the user menu overlays
    setTimeout(function() {
        const didAgentElement = document.getElementsByClassName('didagent_target')[0];
        if (didAgentElement) {
            const didAgentRoot = didAgentElement.firstChild;
            console.warn('App: didAgentRoot.shadowRoot = ' + didAgentRoot.shadowRoot);
            var style = document.createElement( 'style' )
            style.innerHTML = '.didagent__fabio { z-index: 3; }'
            didAgentRoot.shadowRoot.appendChild(style);
        }
    }, 1000);

    const history = useHistory();
    //console.debug('App: history = ', history);

    // Assets in cart
    //const [cartAssets, setCartAssetList] = useState([]);
    let [cartAssets, setCartAssetsList] = useState([]);
    const [cartDetail, setCartDetail] = useState();
    const cartCount = useSelector(state => state.cart.countCart);
    console.warn('App: cartCount = ', cartCount);

    const isAgreeTermsRef = useRef(null);
    //const [isAgreeTerms, setIsAgreeTerms] = useState('off');
    const [isAgreeTerms, setIsAgreeTerms] = useState(false);

    const [userDetails, setUserDetails] = useState({});

    const userAccessToken = useRef(null);
    const emailRef = useRef(null);
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const repasswordRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const registerPasswordRef = useRef(null);
    const repeatPasswordRef = useRef(null);

    const [isExistedUser, setIsExistedUser] = useState(false);
    const [isRegisteredUser, setIsRegisteredUser] = useState(false);
    const [isValidatedUser, setIsValidatedUser] = useState(false);
    const [isLoadingLoginMetakeep, setIsLoadingLoginMetakeep] = useState(false);

    const [loginError, setLoginError] = useState({})
    const [registerError, setRegisterError] = useState({})
    const [registerObject, setRegisterObject] = useState({})
    const [forgotPasswordError, setForgotPasswordError] = useState({})

    //const [isShowMobileNav, setIsShowMobileNav] = useState(false);

    const [loginPasswordVisibility, setLoginPasswordVisibility] = useState(false);
    const [registerPasswordVisibility, setRegisterPasswordVisibility] = useState(false);
    const [repeatRegisterPasswordVisibility, setRepeatRegisterPasswordVisibility] = useState(false);

    const handleChangeLoginPasswordVisibility = () => {
        setLoginPasswordVisibility(!loginPasswordVisibility);
    };

    const handleChangeRegisterPasswordVisibility = () => {
        setRegisterPasswordVisibility(!registerPasswordVisibility);
    };

    const handleChangeRepeatRegisterPasswordVisibility = () => {
        setRepeatRegisterPasswordVisibility(!repeatRegisterPasswordVisibility);
    };

    /*
    const location = useLocation()

    const useQuery = () => {
        console.log('App: useQuery()')
        //const { search } = useLocation();
        const { search } = location;
        console.log('App: useQuery(): search = ', search)
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const params = useQuery();
    */

    /* Moved to OrderDetails.js
    React.useEffect(() => {
        function handleResize() {
            console.warn('resized to: ', window.innerWidth, 'x', window.innerHeight)
            
        }
        window.addEventListener('resize', handleResize)
    });
    */

    useEffect(async() => {
        console.debug('App: useEffect(async() => {...')

        console.debug('    window.location.search = ', window.location.search)
        const queryParameters = new URLSearchParams(window.location.search)
        const referralCode = queryParameters.get('referral-code')
        console.debug('    referralCode = ', referralCode);
        if (referralCode) {
            dispatch(referralCodeUpdate(referralCode));
        }

        /*
        console.log('App: useEffect(): params = ', params)

        //const getTokenBal = setInterval(() => getBalanceToken(), 60000);

        const referralCode = params?.get('referral-code')
        console.log('App: useEffect(): referralCode = ', referralCode);
        if (referralCode) {
            dispatch(referralCodeUpdate(referralCode));
        }
        */
    //};
    //}, 60000 * 25);
    }, []);

    // state
    const referralCode = useSelector((state)=> state?.keyVal?.referralCode);
    console.debug('App: referralCode = ', referralCode);


    //const userAccessToken = localStorage.getItem('userAccessToken');
    //console.log('App: userAccessToken', userAccessToken);
    //alert('App: userAccessToken = '+ userAccessToken);

    //let userAccessToken;

        //const getProfileDetails = async () => {
    const getProfileDetails = useCallback(() => {
        console.debug('App.getProfileDetails()');
        
        /*
        if (!status) {
            // PopUpAlert('Disconnected', 'Connect to view your account userDetails', 'error');
            history.push('/search/all');
            return;
        }
        */

        const config = getConfig();
        //if (!config) {
        //    history.push('/');
        //    return;
        //}

        if (config) {
            //await axiosInstance
            axiosInstance
                .get(`user/get-profile`, config)
                .then((res) => {
                    /*
                    const {
                          firstName
                        , lastName
                        , email
                        , mobileNumber
                        , userName
                        , profilePicUrl
                        , status
                        , addresses
                    } = res.data?.result;
                    */
                    setUserDetails(res.data?.result);
                })
                .catch((err) => {
                    setUserDetails(null);
                });
        }
    //}; // getProfileDetails()
    }, []); // getProfileDetails()

    useEffect(() => {
        console.debug('App: useEffect(() => {...');
    //useCallback(() => {
        //console.log('useCallback()');

        let localStorageValue = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
        console.debug('    localStorageValue = ', localStorageValue);

        // Initialization code comes here
        //userAccessToken.current = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
        if (localStorageValue) userAccessToken.current = localStorageValue;
        console.debug('    userAccessToken.current', userAccessToken.current);

        //userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
        //console.log('App: userAccessToken', userAccessToken);
        //alert('App: userAccessToken = '+ userAccessToken);
        //if (userAccessToken.current) getProfileDetails();
        //if (userAccessToken) getProfileDetails();
        //if (localStorageValue) getProfileDetails();
        if (userAccessToken.current) {
            getProfileDetails();
            fetchListCart();
        }

    }, [getProfileDetails]);

    const [
        productDetails
        //, setProductDetails
    ] = useState(null);
    
    const [
        walletUserDetails
        //, setWalletUserDetails
    ] = useState(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));
    
    const euroValue = useSelector((state) => state.counter.euroValue) || 0;
    const toAddress = decryptData(
        '0x0af02a9f1262fca76388e25ef0840d8043458c6e'
    );

    let walletUserDetailsObject;
    //let tokenBal;
    //let profilePicUrl;
    if (walletUserDetails) {
        walletUserDetailsObject = JSON.parse(walletUserDetails);
    }

    //if (userAccessToken) {
    //    this.getProfileDetails();
    //}

    /*
     * Error: could not find react-redux context value; please ensure the component is wrapped in a <Provider>
     */
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    //const [metaMaskActive, setMetaMaskActive] = useState(false);
    const [ connectWalletBtnDisable, setConnectWalletBtnDisable ] = useState(false);
    const [ loginModal, setLoginModal ] = useState(false);
    const [ isVisibleRegisterDialog, setIsVisibleRegisterDialog ] = useState(false);
    const [ isVisibleLoginDialog, setIsVisibleLoginDialog ] = useState(false);
    const [ isVisibleResetPasswordDialog, setIsVisibleResetPasswordDialog ] = useState(false);

    // const options = new WalletConnectProvider({
    //     infuraId: process.env.REACT_APP_INFURA_ID
    //     , rpc: {
    //         [envChainId]: envRpcUrl
    //     }
    // });

    const providerOptions = {
        //walletconnect: {
        //      package: WalletConnectProvider // required
        //    // , options: options
        //}
    };

    const web3Modal = new Web3Modal({
          network: "mainnet" // optional
        , cacheProvider: false // optional
        , providerOptions // required
    });

    /*
    useEffect(() => {
        console.warn('Header: useEffect(() => {');
        console.warn('userAccessToken = ', userAccessToken);
        console.warn('userAccessToken.current = ', userAccessToken.current);
        if (userAccessToken.current) fetchListCart();
    }, []);
    */

    const showModalDialogForm = () => {
        Swal.withForm({
              title: 'Cool example'
            , text: 'Any text that you consider useful for the form'
            , showCancelButton: true
            , confirmButtonColor: '#DD6B55'
            , confirmButtonText: 'Get form data!'
            , closeOnConfirm: true
            , formFields: [
                 { id: 'name', placeholder:'Name Field' }
               , { id: 'nickname', placeholder:'Add a cool nickname' }
            ]
            //, function(isConfirm) {
            // do whatever you want with the form data
            // // { name: 'user name', nickname: 'what the user sends' }
        })
    };

    const fetchListCart = async () => {
        console.warn("App.fetchListCart()");

        /*
        const config = getConfig();
        const response = await axiosInstance.get('/carts?limit=100&page=1', config);
        console.warn("response", response);
        console.warn("response?.data", response?.data);
        if (response.data) {
            setAssetList(response.data?.result?.assets);
            setCartDetail(response.data?.result);
            //dispatch(countCart(response.data?.result?.totalItems)); // Total fractions
            //dispatch(countCart(response.data?.result?.total)); // Total Assets in cart
        }
        */

        let fetchConfig = getConfig();
        //console.warn('    base_url = ', base_url);
        //console.warn(`    base_url = ${base_url}`);
        //let fetchPath = '/carts?limit=100&page=1';
        let fetchPath = `${ base_url }carts?limit=100&page=1`;
        console.warn('    fetchPath = ', fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                //console.warn('fetch(' + fetchPath + ').then(response)', response);
                //console.warn ('        App.fetchListCart().then(response)', response);
                console.warn ('        App.fetchListCart().then(response)');
                console.debug('        response.ok = ' + response.ok);
                console.debug('        response.status = ' + response.status);
                console.debug('        response.statusText = ' + response.statusText);

                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    //return response.json();
                    response.json().then(function(responseJson) {
                        //console.warn('            fetch("'+fetchPath+'").then(responseJson)', responseJson);
                        console.warn('            responseJson = ', responseJson);
                        console.warn('            responseJson.result = ', responseJson.result);
                        console.debug('            responseJson.result?.assets = ', responseJson.result?.assets);
                        setCartAssetsList(responseJson.result?.assets);
                        setCartDetail(responseJson.result);
                        //dispatch(countCart(responseJson.result?.totalItems)); // Total fractions
                        //dispatch(countCart(responseJson.result?.total)); // Total Assets in cart
                        //dispatch(countCart(response.data?.result?.totalItems)); // Total fractions
                        //dispatch(countCart(response.data?.result?.total)); // Total Assets in cart
                        dispatch(countCart(responseJson.result?.assets.length)); // Total Assets in cart
                    });

                } else { // E.g. 401 Unauthorized
                    response.json().then(function(responseJson) {
                        //console.warn('        fetch("'+fetchPath+'").then(responseJson)', responseJson);
                        console.warn('        responseJson = ', responseJson);
                        //console.warn('responseJson.result = ', responseJson.result);
                        //console.warn('responseJson.result?.assets = ', responseJson.result?.assets);
                        /*
                        setAssetList(responseJson.result?.assets);
                        setCartDetail(responseJson.result);
                        //dispatch(countCart(responseJson.result?.totalItems)); // Total fractions
                        dispatch(countCart(responseJson.result?.total)); // Total Assets in cart
                        */
                        //response.status
                        if (response.status == 401) {
                            setCartAssetsList([]);
                            setCartDetail(null);
                            dispatch(countCart(0)); // set cart count to zero
                        }
                    });
                }
            })
            /*
            .then(function(responseJson) {
                console.warn('fetch("'+fetchPath+'").then(responseJson)', responseJson);
                console.warn(responseJson);
                console.warn('responseJson = ', responseJson);
                console.warn('responseJson.result = ', responseJson.result);
                console.warn('responseJson.result?.assets = ', responseJson.result?.assets);
                setAssetList(responseJson.result?.assets);
                setCartDetail(responseJson.result);
                //dispatch(countCart(responseJson.result?.totalItems)); // Total fractions
                dispatch(countCart(responseJson.result?.total)); // Total Assets in cart
            })
            //*/
            ;

    }; // fetchListCart()

    /*
    //const handleAddToCart = async assetInfo => {
    const handleAddToCart = async (assetInfo, listedAssetInfo) => {
        //console.warn("App.handleAddToCart(assetInfo)", assetInfo);
        console.warn("App.handleAddToCart(assetInfo)", assetInfo, listedAssetInfo);
        console.warn("App.handleAddToCart(assetInfo): assetInfo = ", assetInfo);
        console.warn("App.handleAddToCart(assetInfo): listedAssetInfo = ", listedAssetInfo);

        console.warn("    assetInfo = ", assetInfo);
        console.warn("    assetInfo.id = ", assetInfo.id);
        console.warn("    walletUserDetailsObject = ", walletUserDetailsObject);
        console.warn("    userDetails = ", userDetails);
    */

    const handleAddToCart = async (assetId, listedAssetId) => {
        console.warn('App.handleAddToCart(assetId:'+assetId+', listedAssetId:'+listedAssetId+')');

        if (
            !userDetails
            || !userDetails?.id
        ) {
            PopUpWarning('Please log in or connect your wallet to add items to your cart.', null, 'error');
            return;
        }

        //setAttemptAddToCard((attempt => attempt + 1))

        let bodyObject = { quantity: 1 };

        let fetchConfig = {
            method: 'POST'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: JSON.stringify(bodyObject)
        };
        console.warn("    fetchConfig = ", fetchConfig);

        //let fetchPath = `${ process.env.REACT_APP_API_URL }carts/${ assetInfo.id }/listedAssets/${ assetInfo.listedAssetId }`;
        let fetchPath = `${ process.env.REACT_APP_API_URL }carts/${ assetId }/listedAssets/${ listedAssetId }`;
        console.warn("    fetchPath = ", fetchPath);

        //return;

        let fetchPromise = fetch(fetchPath, fetchConfig);
        fetchPromise
            .then(function(response) {

                console.warn("        response = ", response);
                console.warn("        response.ok = ", response.ok);
                console.warn("        response.status = ", response.status);
                console.warn("        response.statusText = ", response.statusText);

                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.

                    Swal.close();

                    return response.json();

                } else {
                    response.json().then(function(responseJson) {
                        console.warn("        responseJson = ", responseJson);

                        //PopUpWarning(responseJson.message, responseJson.error, 'warning');
                        let sessionTimeoutMessage = "Session has timed out.";
                        if (sessionTimeoutMessage == responseJson.message) {
                            PopUpWarningThen(responseJson.message, responseJson.error, 'warning', function() { logoutUser() });
                        } else {
                            PopUpWarning(responseJson.message, responseJson.error, 'warning');
                        }
                    });
                }
            })

            .then(function(responseJson) {
                console.warn("            responseJson = ", responseJson);
                //getProfileDetails();

                if (responseJson != null) {

                    /* Temporarily removed.
                    sendViewProductEvent({
                        addToCard: true,
                        initiateCheckout: true,
                        initiatePurchase: false
                    });
                    */
                    
                    let totalItems = responseJson.result.totalItems;
                    console.warn("            totalItems = ", totalItems);

                    //dispatch(countCart(totalItems)); // Temporarily removed.

                    history.push("/cart");
                }
            })
            .catch(error => {
                console.warn("            error = ", error);
                console.warn("            error?.message = ", error?.message);
                PopUpAlert('Alert', error?.message, 'error');
            });
    }; // handleAddToCart(assetInfo)

    let fetchResetPassword = async (bodyObject) => {
        console.log('App.fetchResetPassword(bodyObject)', bodyObject);

        //setLoginModal(false);
        //setIsLoadingLoginMetakeep(true);

        //try {

            axiosInstance
                .post(`/user/reset-password`, bodyObject)
                .then(async(res) => {
                    console.log('    res = ', res);
                    console.log('    res?.data = ', res?.data);
                    console.log('    res?.data?.result = ', res?.data?.result);

                    /*
                    let userdata = res.data?.result;
                    console.log('               userdata = ', userdata);
                    localStorage.setItem(
                          NAMES_CONSTANTS.USER_WALLET_DETAILS
                        , JSON.stringify({ accounts, balance, userId: userdata?.userId, email: userdata.email })
                    );
                    localStorage.setItem("tokenbalance", balance);
                    localStorage.setItem(localStorageKeyNameUserAccessToken, userdata?.accessToken);
                    localStorage.setItem('network', walletType);

                    console.log('               localStorage.getItem('+NAMES_CONSTANTS.USER_WALLET_DETAILS+') = ', localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));

                    let userAccessToken = localStorage.getItem(localStorageKeyNameUserAccessToken);
                    console.log('               userAccessToken = ', userAccessToken);
                    //alert('App: userAccessToken = '+ userAccessToken);
                    if (userAccessToken) getProfileDetails();

                    dispatch(setUser({ 
                        accounts,
                        balance,
                        userId: userdata?.userId,
                        email: userdata.email,
                        network: walletType,
                    }))

                    dispatch(increment());
                    dispatch(details({ accounts, balance }));
                    */

                    PopUpAlert('Check your email to reset your password.', 'Continue to reset password.', 'success')
                    .then(result => {
                        setLoginModal(true);
                        /*
                        getBalanceToken();
                        if (referralCode) {
                            dispatch(referralCodeUpdate(null))
                        }
                        */
                    });
                }).catch(err => {
                    console.log('    err = ', err);
                    console.log('    err?.response = ', err?.response);
                    console.log('    err?.response?.data = ', err?.response?.data);
                    console.log('    err?.response?.data?.message = ', err?.response?.data?.message);
                    let respErr = err.response?.data?.message || 'An error occurred while sending the reset password email.';
                    PopUpAlert('Alert', respErr, 'error');
                    //setConnectWalletBtnDisable(false)
                }
            )  // axiosInstance.post()

        /*
        } catch (err) {
            Swal.hideLoading()
        } finally {
            console.log('Swal.hideLoading()');
            Swal.hideLoading()
            console.log('setLoginModal(false)');
            setLoginModal(false);
            console.log('setConnectWalletBtnDisable(false)');
            setConnectWalletBtnDisable(false);
            console.log('setIsLoadingLoginMetakeep(false)');
            setIsLoadingLoginMetakeep(false);
        }
        */
    }; 

    const solicitEmailAddress = () => {
        Swal.fire({
              title: 'Connect to your system wallet'
            , showCloseButton: true
            , showConfirmButton: true
            , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
            , confirmButtonText: 'Connect'
            , buttonsStyling: false
            , customClass: {
                  container: 'custom-container'
                , popup: 'custom-popup custom-popup-add-email'
                , confirmButton: "gold large stretch rounded"
            }
            , input: 'email'
            , inputLabel: 'Please add your email address'
            //, inputValue: email
            , inputValue: 'service.account@wearefuturetech.com'
            , inputPlaceholder: 'Enter your email address'
        }).then((result) => {
            if (result.value) {
                
                alert("Result: " + result.value);
            }
        });
    };

    const getConfig = () => {
        //console.warn('App.getConfig()');

        let localStorageKeyNameUserAccessToken = NAMES_CONSTANTS.USER_ACCESS_TOKEN;
        console.log('localStorageKeyNameUserAccessToken = ', localStorageKeyNameUserAccessToken);

        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(localStorageKeyNameUserAccessToken),
            }
        };
        /*
        if (!localStorage.getItem("userAccessToken")) {
            PopUpAlert('Alert..', 'No wallet connection found', 'error')
            return
        }
        */
        return config
    };

    let fetchUpdateProfile = (bodyObject) => {

        let fetchConfig = {
            method: 'PATCH'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: JSON.stringify(bodyObject)
        };

        let fetchPath = `${base_url}user/update`;
        let fetchPromise = fetch(fetchPath, fetchConfig);
        fetchPromise
            .then(function(response) {
                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    Swal.close();
                    return response.json();
                }
            })
            .then(function(responseJson) {
                getProfileDetails();
            });
    }; // fetchUpdateProfile()

    const showAddDeliveryAddressModal = () => {

        //let productName = productDetails?.name;
        //let creatorName = productDetails?.creatorName;

        let divFlex = '<div class="flex" style="width: 100%;">';

        if (!userDetails.firstName) {
            let inputFirstName = '<div class="input"><input type="text" name="firstName" id="firstName" placeholder="First Name" /></div>';
            let inputLastName = '<div class="input"><input type="text" name="lastName" id="lastName" placeholder="First Name" /></div>';
            let rowFirstName = '<div class="flex-item" style="text-align: left;">' + inputFirstName + '</div>';
            let rowLastName = '<div class="flex-item" style="text-align: left;">' + inputLastName + '</div>';
            divFlex += rowFirstName;
            divFlex += rowLastName;
        }

        let inputAddressLine1 = '<div class="input"><input type="text" name="addressLine1" id="addressLine1" placeholder="Address line 1" /></div>';
        let inputAddressLine2 = '<div class="input"><input type="text" name="addressLine2" id="addressLine2" placeholder="Address line 1" /></div>';
        let inputPostalCode = '<div class="input"><input type="text" name="postalCode" id="postalCode" placeholder="ZIP/Postal Code" /></div>';
        let inputTownCity = '<div class="input"><input type="text" name="townCity" id="townCity" placeholder="Town / City" /></div>';
        let inputCountyStateProvince = '<div class="input"><input type="text"  name="countyStateProvince" id="countyStateProvince" placeholder="State" /></div>';

        let row1 = '<div class="flex-item" style="text-align: left;">' + inputAddressLine1 + '</div>';
        let row2 = '<div class="flex-item" style="text-align: left;">' + inputAddressLine2 + '</div>';
        let row3 = '<div class="flex-item" style="text-align: left;">' + inputPostalCode + '</div>';
        let row4 = '<div class="flex-item" style="text-align: left;">' + inputTownCity + '</div>';
        let row5 = '<div class="flex-item" style="text-align: left;">' + inputCountyStateProvince + '</div>';

        divFlex = divFlex + row1 + row2 + row3 + row4 + row5 + '</div>';
        let form = '<form id="address-form">' + divFlex + '</form>';
        let htmlString = '<p class="title" style="text-align: left;">Please add at least one delivery address</p>';
        htmlString += form;

        Swal.fire({

              title: 'Add Delivery Address'
            , showCloseButton: true
            , showConfirmButton: true
            , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
            //, confirmButtonText: 'Checkout'
            , confirmButtonText: 'Add Address'
            , buttonsStyling: false

            , preConfirm: () => {
                

                /*
                
                
                
                
                
                */
                let addressForm = document.getElementById("address-form");
                
                const inputElements = addressForm.elements;
                for (let i = 0; i < inputElements.length; i++) {
                    
                }

                const addressLine1InputElement = addressForm.elements['addressLine1'];
                const addressLine2InputElement = addressForm.elements['addressLine2'];
                const townCityInputElement = addressForm.elements['townCity'];
                const countyStateProvinceInputElement = addressForm.elements['countyStateProvince'];
                const postalCodeInputElement = addressForm.elements['postalCode'];

                const addressLine1 = addressLine1InputElement.value;
                const addressLine2 = addressLine2InputElement.value;
                const townCity = townCityInputElement.value;
                const countyStateProvince = countyStateProvinceInputElement.value;
                const postalCode = postalCodeInputElement.value;

                
                
                
                
                

                /*
                
                
                
                */
                let addressObject = {
                      "addressLine1": addressLine1
                    , "addressLine2": addressLine2
                    , "townCity": townCity
                    , "countyStateProvince": countyStateProvince
                    , "postalCode": postalCode
                    //, "countryCode": countryCode
                    , "countryCode": "US"
                    //, "isMainAddress": true
                    //, "isDeleted": false
                    //, "id": 11
                };
                
                

                
                
                if (userDetails.addresses) {
                    userDetails.addresses.push(addressObject);
                } else {
                    userDetails.addresses = [
                        addressObject
                    ];
                }
                

                // firsName lastName and userName
                const firstNameInputElement = addressForm.elements['firstName'];
                const lastNameInputElement = addressForm.elements['lastName'];
                if (firstNameInputElement) {
                    const firstName = firstNameInputElement.value;
                    userDetails.firstName = firstName;
                }
                if (lastNameInputElement) {
                    const lastName = lastNameInputElement.value;
                    userDetails.lastName = lastName;
                }
                if (!userDetails.userName) {
                    if (userDetails.email) userDetails.userName = userDetails.email;
                }

                fetchUpdateProfile(userDetails);

                return false;
            }
            , customClass: {
                  container: 'custom-container'
                , popup: 'custom-popup custom-popup-add-address'
                , confirmButton: "gold large stretch rounded"
            }
            //, input: 'email'
            //, inputLabel: 'Please add your email address'
            //, inputPlaceholder: 'Enter your email address'
            , html: htmlString
        //})
        }).then((result) => {
            if (result.value) {
                
                showModalDialogForm();
            }
        });
    }; // showAddDeliveryAddressModal = ()

    const attachListener = () => {
        
        //document.addEventListener("message", (data) => 
        document.addEventListener("message", (data) => alert("Event is", data));
    };

    let createSardineOrder = sardineOrderObject => {
        

        let fetchConfig = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization:
                  'Basic MzVmYjk1MjctMjk1OC00ZDc2LWFhYjEtN2IzNTA1YjUyNTQzOjU0MTkxZDIwLTNlZDktNDgxNS1iMTkyLTI0OGFlNjNjODExYw=='
            },
            body: JSON.stringify(sardineOrderObject)
        };

        let submitPath = 'https://api.sandbox.sardine.ai/v1/auth/client-tokens';
        let fetchPromise = fetch(submitPath, fetchConfig);
        
        
        

        fetchPromise
            .then(function (response) {
                
                
                
                
                
                if (response.ok) {
                  // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                  Swal.close();
                  return response.json();
                }
            })
            .then(function (responseJson) {
                
                
                

                let widgetUrl =
                  'https://crypto.sandbox.sardine.ai/?client_token=' +
                  responseJson.clientToken +
                  '&show_features=true';
                //let htmlString = '<iframe src="' + widgetUrl +'" style="width: 100%; height: 650px;" onload="attachListener()"></iframe>';
                let htmlString =
                  '<iframe src="' +
                  widgetUrl +
                  '" style="width: 100%; height: 650px;"></iframe>';
                attachListener();

                Swal.fire({
                  showConfirmButton: false,
                  //, title: 'Checkout'
                  //, showCloseButton: false
                  //, closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
                  customClass: {
                    container: 'custom-container',
                    //, popup: 'custom-popup custom-popup-checkout'
                    popup: 'custom-popup custom-popup-iframe'
                  },
                  //, input: 'email'
                  //, inputLabel: 'Please add your email address'
                  //, inputPlaceholder: 'Enter your email address'
                  html: htmlString
                  //})
                  //}).then((result) => {
                  //    if (result.value) {
                  //        
                  //    }
                });
            })

            .catch(error => {
                console.error(
                    'There has been a problem with your fetch operation:',
                    error
                );
            });
    };

    let fetchCreateOrder = (bodyObject, address) => {
        

        
        
        alert('App.itemId = ' + itemId);

        /*
        let addresses = userDetails?.addresses;
        //
        //
        
        let address = addresses[0];
        
        */
        

        let fetchConfig = {
            method: 'POST'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                //, 'Authorization': bearerString
                //, Authorization: 'Bearer ' + localStorage.getItem('userAccessToken')
                //, Authorization: userAccessToken.current
                , Authorization: userAccessToken
            }
            , body: JSON.stringify(bodyObject)
        };
        //
        //
        

        
        //let fetchPath = `${base_url}asset/checkout/${id}`;
        let fetchPath = `${base_url}asset/checkout/${itemId}`;
        

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                
                
                

                if (response.ok) {
                    return response.json();
                } else if (response.status === 422) { // 422 Unprocessable Entity
                    return response.json(); // {"success":false,"message":"Please provide your email or verify it"}
                } else if (response.status === 401) { // 401 Unauthorized
                    return response.json(); // {"success":false,"message":"Authorization token invalid or expired"}
                } else {
                    PopUpAlert('Failure', 'Order Not Created', 'error').then(
                        res => {
                            //history.push('/order-userDetails');
                            
                        }
                    );
                }

            }).then(function(responseJson) {

                if (responseJson.success === false) {

                    if (responseJson.message.contains("Authorization token invalid")) {
                        PopUpAlert('Failure', responseJson.message, 'error')
                            .then(
                                res => {
                                    //history.push('/order-userDetails');
                                    window.location.reload();
                                }
                            );
                    } else {
                        PopUpAlert('Failure', responseJson.message, 'error').then(
                            res => {
                                //history.push('/order-userDetails');
                                window.location.reload();
                            }
                        );
                    }
                }

                /*
                
                //PopUpAlert('Your Email Address', responseJson.message, 'warning').then(
                //PopUpAlert(responseJson.message, '', 'warning').then(
                PopUpAlert('', responseJson.message, 'warning').then(
                    res => {
                        //history.push('/order-userDetails');
                        
                    }
                );
                */

                if (responseJson) {

                    //let orderResultObject = responseJson.result;
                    //let orderId = orderResultObject.id.toString();
                    //let userId = orderResultObject.userId.toString();
                    //let productName = productDetails?.name;
                    //let creatorName = productDetails?.creatorName;
                    //let ipfsPreviewImageUrl = productDetails?.ipfsPreviewImageUrl;
                    let regularPrice = productDetails?.regularPrice;

                    let sardineOrderObject = {

                        "side": "BUY",
                        "listing_id": "{listing_id}",
                        "quantity": "2500",
                        "limit_price": regularPrice,
                        "status": "OPEN"

                    }; // let sardineOrderObject

                    
                    createSardineOrder(sardineOrderObject);

                } else {

                    PopUpAlert('Failure', 'No JSON body in response', 'error').then(
                        res => {
                            //history.push('/order-userDetails');
                            
                        }
                    );

                } // if (responseJson)

            }); // fetchPromise.then(function(response)

    }; // fetchCreateOrder(bodyObject, address)

    const showSardinePaymentWidget = (address) => {

        //let productName = productDetails?.name;
        //let creatorName = productDetails?.creatorName;
        //let toAddress = 'toAddress';
        let fields = { email: 'ddoherty@wearefuturetech.com' };

        let bodyObject = {
              ethPrice: productDetails?.regularPrice * euroValue
            //, from: account[0]
            , from: walletUserDetailsObject.account[0]
            , quantity: 1
            , salePrice: productDetails?.regularPrice
            , saleType: productDetails?.saleType
            , status: 'pending'
            , to: toAddress
            , paymentType: 'sardine'
            , cardDetails: {
                metadata: {
                      email: fields?.email
                    , sessionId: 'DE6FA86F60BB47B379307F851E238617'
                    , ipAddress: '189.169.121.59'
                    // "phoneNumber": ""
                }
                , amount: {
                      amount: productDetails?.regularPrice
                    //, currency: 'USD'
                    , currency: 'MATIC'
                }
                //, source: {
                //    id: cardInfo.circleCardId
                //    , type: 'card'
                //}
                //, autoCapture: true
                , idempotencyKey: ''
                //, keyId: keyId
                , verification: 'cvv'
                , description: 'Payment'
                //, encryptedData: window.btoa(encryptedData)
                //, "channel": "ba943ff1-ca16-49b2-ba55-1057e70ca5c7"
            }
        };

        //
        //
        

        fetchCreateOrder(bodyObject, address);
    }; // showSardinePaymentWidget(address)

    const startCheckoutProcess = () => {
        console.warn('startCheckoutProcess()');

        let htmlDescription = "How would you like to log in? You can connect using an existing wallet with Metamask, or create a RealSplit™ system wallet.";

        Swal.fire(
            {
                  html: `<p class="title">${ htmlDescription }</p>`
                , showCloseButton: true
                , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'

                , customClass: {
                      container: 'custom-container'
                    , popup: 'custom-popup custom-popup-select-wallet'
                    , confirmButton: "gold large"
                }

                , buttonsStyling:    false
                , showDenyButton:    true
                , showConfirmButton: true
                , showCancelButton:  true

                , confirmButtonText: '' // Metamask
                , cancelButtonText:  '' // WalletConnect
                , denyButtonText:    '' // Sytem Wallet (Metakeep)

            }
        ).then((result) => {

            if (result.isConfirmed) {
                handleConnectWallet();
            } else if (result.dismiss) {
                if (result.dismiss === Swal.DismissReason.cancel) {
                    clickSystemWallet();
                } else {
                    //showAddDeliveryAddressModal();
                    //solicitEmailAddress();
                    //clickSystemWallet();
                }
            } else {
                //if (result.value) {
                //    
                //    showAddDeliveryAddressModal();
                //    //clickSystemWallet();
                //}
                //props.connectWalletFunction();
                handleConnectWallet();
            }
        });
    };

    const clickAddIcon = function () {
        //alert('clickAddIcon');
        
        showAddDeliveryAddressModal();
    };
    window.clickAddIcon = clickAddIcon;

    const showSelectDeliveryAddressModal = () => {
        
        let addresses = userDetails?.addresses;
        if (!addresses) {
            showAddDeliveryAddressModal();
            return;
        }

        let addressBoxes = "";
        let address;
        let addressIndex = 0;
        for (address of addresses) {

            let addressBox = '<div class="address-box selected" style="line-height: 20px">'
                                + '<div>'
                                    + '<input type="radio"'
                                        + ' id="address'
                                        + addressIndex
                                        + '" name="addressIndex"'
                                        //+ ' checked="checked"'
                                        + ' value="' + addressIndex + '" />'
                                + '</div>'
                                + '<label for="address' + addressIndex + '">'
                                    //+ 'Address line 1'
                                    + address.addressLine1
                                    + '<br/>' + address.addressLine2
                                    + '<br/>' + address.townCity
                                    + '<br/>' + address.countyStateProvince
                                    + '<br/>' + address.postalCode
                                    + '<br/>' + address.countryCode
                                + '</label>'
                            + '</div>';
            addressBoxes += addressBox;
            addressIndex++;
        }

        /* For development
        let addressBox1 = '<div class="address-box selected" style="line-height: 20px">'
                                + '<div>'
                                    + '<input type="radio"'
                                    + ' id="address'
                                    + addressIndex
                                    + '" name="addressIndex"'
                                    + ' value="'
                                    + addressIndex
                                    + '" />'
                                + '</div>'
                                + '<label for="address' + addressIndex + '">'
                                    + address.addressLine1
                                    + '<br/>' + address.addressLine2
                                    + '<br/>' + address.townCity
                                    + '<br/>' + address.countyStateProvince
                                    + '<br/>' + address.postalCode
                                    + '<br/>' + address.countryCode
                                + '</label>'
                            + '</div>';
        addressBoxes += addressBox1;
        */

        let addNewAddressBox = '<div class="address-box gold-font" style="line-height: 20px; cursor: pointer;" onclick="clickAddIcon()">'
                                    + '<img src="/images/icons/add-icon-gold.svg" />'
                                    + 'Add New Address'
                                + '</div>';

        let divTableString = '<div class="flex" style="width: 100%;"><form id="address-form">' + addressBoxes + '</form>' + addNewAddressBox + '</div>'

        let htmlString = '<p class="title" style="text-align: left;">Please select where would you like your product to be delivered</p>';

        htmlString += divTableString;

        Swal.fire({

              title: 'Shipping'

            // Confirm button
            , showConfirmButton: true
            , confirmButtonText: 'Continue to Payment'

            // Close button
            , showCloseButton: true
            , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'

            , buttonsStyling: false

            //, showDenyButton:    true
            , showCancelButton:  true

            , cancelButtonText:  'Cancel'
            //, denyButtonText:    'Deny'

            //*
            , preConfirm: () => {
                

                /*
                var addressRadioButtons = document.getElementsByName('addressIndex');
                //
                //

                let addressIndexString = -1;
                for (let i = 0; i < addressRadioButtons.length; i++) {
                    //
                    //
                    if (addressRadioButtons[i].checked) {
                        //
                        //
                        addressIndex = parseInt(addressRadioButtons[i].value);
                    }
                }
                //*/
                //let addressRadioButtonsForm = document.getElementById('address-form');
                let checkboxes= document.querySelectorAll('input[name="addressIndex"]:checked');
                //let output= [];
                checkboxes.forEach((checkbox) => {
                    if (checkbox.checked) addressIndex = parseInt(checkbox.value);
                });
                
                /*
                addressRadioButtons.forEach(function(addressRadioButton) {
                    alert('addressRadioButton', addressRadioButton)
                    if (addressRadioButton.checked) {
                        alert('addressRadioButton.value = ' + addressRadioButton.value)
                        alert('addressRadioButton.id = ' + addressRadioButton.id)
                    }
                });
                //*/

                //
                //let addressId = parseInt(document.getElementById('addressId').value);
                //
                //alert('addressId = ' + addressId);

                let addresses = userDetails?.addresses;
                
                
                let address = addresses[addressIndex];
                

                /*
                
                
                

                //let toAddress = 'toAddress';
                let fields = { email: 'ddoherty@wearefuturetech.com' };

                let bodyObject = {
                      ethPrice: productDetails?.regularPrice * euroValue
                    //, from: account[0]
                    , from: walletUserDetailsObject.account[0]
                    , quantity: 1
                    , salePrice: productDetails?.regularPrice
                    , saleType: productDetails?.saleType
                    , status: 'pending'
                    , to: toAddress
                    , paymentType: 'circle'
                    , cardDetails: {
                        metadata: {
                              email: fields?.email
                            , sessionId: 'DE6FA86F60BB47B379307F851E238617'
                            , ipAddress: '189.169.121.59'
                            // "phoneNumber": ""
                        }
                        , amount: {
                              amount: productDetails?.regularPrice
                            , currency: 'USD'
                        }
                        //, source: {
                        //    id: cardInfo.circleCardId
                        //    , type: 'card'
                        //}
                        , autoCapture: true
                        , idempotencyKey: ''
                        //, keyId: keyId
                        , verification: 'cvv'
                        , description: 'Payment'
                        //, encryptedData: window.btoa(encryptedData)
                        //, "channel": "ba943ff1-ca16-49b2-ba55-1057e70ca5c7"
                    }
                };
                
                

                fetchCreateOrder(bodyObject);
                */
                showSardinePaymentWidget(address);

                //Swal.close();
                //return true;
                return false;
            }
            //*/
            , customClass: {
                  container: 'custom-container'
                , popup: 'custom-popup custom-popup-add-address'
                //, popup: 'custom-popup custom-popup-select-address'
                , confirmButton: "gold large stretch rounded"
                //, cancelButton: "gold large stretch rounded"
                //, cancelButton: "clear white large stretch rounded"
                , cancelButton: "large white rounded gilded stretch"
            }
            //, input: 'email'
            //, inputLabel: 'Please add your email address'
            //, inputPlaceholder: 'Enter your email address'
            , html: htmlString
        //})
        }).then((result) => {

            //if (result.value) {
            //    
            //    showModalDialogForm();
            //}

            if (result.isConfirmed) {
                
                //setOpen(true);
                //showSelectDeliveryAddressModal();
                //showSuccessfulPurchaseModal();
                showSardinePaymentWidget();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                
                //showAddDeliveryAddressModal();
                //solicitEmailAddress();
                //clickSystemWallet();
                startCheckoutProcess();
            } else {
                
                /*
                //if (result.value) {
                //    
                    showAddDeliveryAddressModal();
                    //clickSystemWallet();
                //}
                */
            }
        });
    }; // showSelectDeliveryAddressModal()

    /*
    const getDeliveryAddress = () => {

        //userDetails.address = "123 Main St., Austin, Texas 12345";

        //if (!userDetails.address) {
        if (
            !userDetails.addresses
            || userDetails.addresses.length == 0
        ) {
            showAddDeliveryAddressModal();
        } else {
            //chooseDeliveryAddressModal();
            showSelectDeliveryAddressModal();
        }
    };
    */

    const clickSystemWallet = () => {
        console.warn('clickSystemWallet()');

        console.log('    userDetails = ', userDetails);
        if (
            userDetails == null
            || Object.keys(userDetails).length === 0 // check if userDetails object is empty
        ) {
            setIsVisibleLoginDialog(true);
            return;
        }
        
        if (!userDetails.email) {
            solicitEmailAddress();
        } else {
            setIsVisibleLoginDialog(true);
            //getDeliveryAddress();
            //showSelectDeliveryAddressModal();
        }
    };

    const showLoginDialog = () => {
        console.warn('App.showLoginDialog()');

        if (isVisibleRegisterDialog) setIsVisibleRegisterDialog(false);

        setIsVisibleLoginDialog(true);
    };

    const showRegisterDialog = () => {
        console.warn('App.showRegisterDialog()');
        setIsVisibleRegisterDialog(true);
    };

    const clickForgotPassword = () => {
        console.warn('App.clickForgotPassword()');

        console.log('    userDetails = ', userDetails);
        if (
            userDetails == null
            || Object.keys(userDetails).length === 0 // check if userDetails object is empty
        ) {

            if (isVisibleRegisterDialog) setIsVisibleRegisterDialog(false);
            if (isVisibleLoginDialog) setIsVisibleLoginDialog(false);

            setIsVisibleResetPasswordDialog(true);
            return;
        }
    };

    const clickSendVerification = () => {
        console.warn('clickSendVerification()');

        axiosInstance
            .post('/user/send-verification', {})
            .then(async(res) => {
                console.log('    res = ', res);
            })
            .catch(err => {
                console.log('    err = ', err);
                //let respErr = err.response?.data?.message || 'Please switch to polygon main network'
                //PopUpAlert('Alert', respErr, 'error')
                //setConnectWalletBtnDisable(false)
            });
    };

    const chooseWalletType = (id) => {
        console.log('chooseWalletType('+id+')');

        itemId = id;

        //let htmlDescription = "How would you like to connect?<br/>You can use an existing wallet or create a platform wallet.";
        let htmlDescription = "How would you like to log in?<br/>You can connect using an existing wallet with Metamask, or create a RealSplit™ system wallet.";

        Swal.fire({

              html: `<p class="title">${ htmlDescription }</p>`
            , showCloseButton: true
            , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'

            , customClass: {
                  container: 'custom-container'
                , popup: 'custom-popup custom-popup-select-wallet'
                , confirmButton: "gold large"
            }

            , buttonsStyling:    false
            //, showDenyButton:    true
            , showConfirmButton: true
            , showCancelButton:  true

            , confirmButtonText: '' // Metamask
            , cancelButtonText:  '' // System Wallet (Metakeep)
            //, denyButtonText:    '' // WalletConnect
        }).then((result) => {

            console.log('result.isConfirmed = ', result.isConfirmed);
            console.log('result.dismiss = ', result.dismiss);
            console.log('result.value = ', result.value);
            if (result.isConfirmed) { // RealSplit
                //handleConnectWallet();
                clickSystemWallet();
            } else if (result.dismiss) { // System Wallet / Metakeep
                if (result.dismiss === Swal.DismissReason.cancel) {
                    //clickSystemWallet();
                    handleConnectWallet();
                } else {
                    //showAddDeliveryAddressModal();
                    //solicitEmailAddress();
                    //clickSystemWallet();
                }

            } else { // WalletConnect
                if (result.value) {
                    
                //    showAddDeliveryAddressModal();
                //    //clickSystemWallet();
                }
                handleConnectWallet();
            }
        });
    }; // chooseWalletType(id)


    const connectWallet = async (provider, walletType = 'metamask', body = {}) => {
        console.log('connectWallet(provider, '+walletType+', body');

        const web3 = new Web3(provider);
        const chainId = await web3.eth.getChainId();
        const network = await checkNetwork(chainId);
        console.log('network', network);

        if (network) {

            const ethAccounts = await web3.eth.getAccounts();
            console.log('ethAccounts', ethAccounts);
            let balance = await web3.eth.getBalance(ethAccounts[0]);
            balance = web3.utils.fromWei(balance, "ether");
            console.log('ethAccounts', ethAccounts);
         
            if (ethAccounts) {
                try {
                    let reqBody;
                    
                    if (walletType === 'metakeep') {
                        reqBody = body
                    } else {
                        const signature = await web3.eth.personal.sign(process.env.REACT_APP_SIGN_MESSAGE, ethAccounts[0], "");
                        reqBody = { signature, publicAddress: ethAccounts[0] }
                    }
                    
                    if (referralCode) {
                        reqBody.referralCode = referralCode
                    }
                    
                    axiosInstance
                        .post(`/user/authenticate${walletType === 'metakeep' ? '?type=METAKEEP' : ''}`, reqBody)
                        .then(async(res) => {
                            console.log('res', res);
                            let userdata = res.data?.result;
                            localStorage.setItem(
                                  NAMES_CONSTANTS.USER_WALLET_DETAILS
                                , JSON.stringify({ ethAccounts, balance, userId: userdata?.userId, email: userdata.email })
                            );
                            localStorage.setItem("tokenbalance", balance);
                            localStorage.setItem("userAccessToken", userdata?.accessToken);
                            localStorage.setItem('network', walletType);

                            dispatch(setUser({ 
                                  ethAccounts
                                , balance
                                , userId: userdata?.userId
                                , email: userdata.email
                                , network: walletType
                            }))

                            dispatch(increment());
                            dispatch(details({ ethAccounts, balance }));

                            PopUpAlert('Connection Successful', 'You are now connected', 'success')
                            .then(result => {
                                setLoginModal(false);
                                getBalanceToken();
                                if (referralCode) {
                                    dispatch(referralCodeUpdate(null))
                                }
                            });
                        }).catch(err => {
                            let respErr = err.response?.data?.message || 'Please switch to polygon main network'
                            PopUpAlert('Alert', respErr, 'error')
                            setConnectWalletBtnDisable(false)
                        }
                    )  // axios.post()
                } catch (err) {
                    if (err.code === 4001) {
                        web3Modal.clearCachedProvider();
                        setConnectWalletBtnDisable(false);
                        PopUpAlert(
                            "Alert",
                            "User denied the signature request",
                            "error"
                        );
                        // alert("User denied the signature request");
                    } else {
                        setConnectWalletBtnDisable(false);
                        if (
                               err?.toString()?.includes("User rejected")
                            || err?.toString()?.includes("User denied")
                        ) {
                            PopUpAlert(
                                "Alert",
                                "User denied the signature request",
                                "error"
                            );
                        } else {
                            PopUpAlert("Alert", err.response?.data?.message, "error");
                        }
                    }
                }
            }
        }
    }

    const handleConnectWallet = async (e) => {
        console.log('App: handleConnectWallet(e)');

        let redirected = false;

        try {

            console.log('typeof window.web3');
            if (typeof window.web3 !== 'undefined') {

                await web3Modal.clearCachedProvider();
                let ethAccounts = await window.ethereum.request({ method: "eth_accounts" });
                console.log('App: ethAccounts', ethAccounts);

            } else {

                await web3Modal.clearCachedProvider();
                await Swal.fire({
                      html: 'Metamask not found, you can install it by clicking below or cancel to proceed with a system wallet'
                    , icon: 'error'
                    , confirmButtonText: "Install"
                    , showCancelButton: true
                    , confirmButtonColor: "var(--color-gold)"
                    , iconColor: 'var(--color-gold)'
                    , customClass: { confirmButton: "gold large stretch", cancelButton: "large stretch" }
                    , cancelButtonColor: "#6e7881"
                    , cancelButtonText: "Proceed with a system wallet"
                }).then((result) => {
                    if (result.isConfirmed) {
                        redirected = true;
                        window.open('https://metamask.io/download',"_blank");
                    } else if (!result.isConfirmed) {
                        redirected = true;
                        return clickSystemWallet()
                    }
                })
            }

            if (redirected) return;

            if (connector?.connected) connector?.killSession();

            let provider;
            try {
                provider = await web3Modal.connect();
                setConnectWalletBtnDisable(true)
                setLoading(true)
            } catch (err) {
                // PopUpAlert('Alert', err, 'error');
                setConnectWalletBtnDisable(false)
                await web3Modal.clearCachedProvider();
                return;
            }
          // set authentication with provider
          await connectWallet(provider);
        } finally {
            setLoading(false);
            setConnectWalletBtnDisable(false)
        }
    }; // handleConnectWallet()

    const showCheckoutModal = () => {

        let productName = productDetails?.name;
        let creatorName = productDetails?.creatorName;

        let divCellString
            = '<div class="cell" style="text-align: left;">'
            + productDetails?.regularPrice
            + '</div>';
        let divCellCurrencyString
            = '<div class="cell" style="text-align: right;"><strong>'
            + currency
            + '</strong></div>';
        let divRowString
            = '<div class="row">'
            + divCellString
            + divCellCurrencyString
            + '</div>';

        let divCellString0 =
            '<div class="cell" style="text-align: left;color: #777E91; ">You will pay</div>'
        let divCellCurrencyString0 = '<div class="cell" style="text-align: right;"><strong>'
            + productDetails?.regularPrice
            + ' '
            + currency
            + '</strong></div>';
        let divRowString0 = '<div class="row">'
            + divCellString0
            + divCellCurrencyString0
            + '</div>';

        let divTableString = '<div class="table" style="width: 100%;">'
            + divRowString
            + divRowString0
            + '</div>';

        let htmlString = '<p class="title" style="text-align: left;">'
            + 'You are about to purchase '
            + '<strong>'
            //+ EL CAPITAN BLUE GOLD from Craig shah<br/>Thank you for choosing our luxury NFT watches</p>'
            + productName
            + '</strong> from <strong>'
            + creatorName
            //+ '</strong><br/>Thank you for choosing our luxury NFT watches</p>'
            + '</strong>'
            + '</p>';

        htmlString += divTableString;

        Swal.fire({
              title: 'Checkout'
            , showCloseButton: true
            , showConfirmButton: true
            , showCancelButton:  true
            , buttonsStyling: false
            , cancelButtonText:  'Cancel'
            , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
            //, confirmButtonText: 'Checkout'
            , confirmButtonText: 'Select Shipping address and Buy'
            , customClass: {
                  container: 'custom-container'
                , popup: 'custom-popup custom-popup-checkout'
                , confirmButton: "gold large stretch rounded"
                , cancelButton: "large white rounded gilded stretch"
            }
            , html: htmlString
        }).then((result) => {
            
            if (result.value) {
                
                //showAddDeliveryAddressModal();
                showSelectDeliveryAddressModal();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                
                //showAddDeliveryAddressModal();
            }
        });
    }; // showCheckoutModal()

    const clickConnectWalletButton = () => {
        
        //alert('App.clickConnectWalletButton()');
        setLoginModal(true);
    };

    const loginClose = () => {
        
        setLoginModal(false)
        setConnectWalletBtnDisable(false)
    };

    const validateUserExist = async () => {
        console.log('validateUserExist()');

        let usernameValue = usernameRef.current.value;

        if (!usernameValue) {
            let loginError = {};
            loginError['username'] = 'Please enter your username';
            //formIsValid = false;
            setLoginError(loginError);
            return;
        }

        const { data: user } = await axiosInstance.post(`user/check-exist`, { username: usernameValue });
        console.log('user = ', user);

        setIsValidatedUser(true);

        if (user.result.existed) {
            setIsExistedUser(true)
        } else {
            setIsExistedUser(false)
        }
    };

    const registerUser = async () => {
        console.warn('registerUser()');

        console.warn('registerPasswordRef.current = ', registerPasswordRef.current);

        let usernameValue = emailRef.current.value;
        let firstNameValue = firstNameRef.current.value;
        let lastNameValue = lastNameRef.current.value;
        //let registerPasswordValue = registerPasswordRef.current.value;
        let registerPasswordValue = registerPasswordRef?.current?.value;
        let repeatPasswordValue = repeatPasswordRef?.current?.value;
        let isAgreeTermsValue = isAgreeTermsRef.current.value;

        console.warn('usernameValue = ', usernameValue);
        console.warn('firstNameValue = ', firstNameValue);
        console.warn('lastNameValue = ', lastNameValue);
        console.warn('registerPasswordValue = ', registerPasswordValue);
        console.warn('repeatPasswordValue = ', repeatPasswordValue);
        console.warn('isAgreeTermsValue = ', isAgreeTermsValue);
        console.warn('isAgreeTermsRef?.current?.checked = ', isAgreeTermsRef?.current?.checked);

        if (!usernameValue) {
            let registerError = {};
            registerError['username'] = 'Please enter your email address';
            setRegisterError(registerError);
            return;
        } else {
            registerError['username'] = '';
            delete registerError['username'];
            setRegisterError(registerError);
        }

        let message = 'Please enter your password';
        let fieldName = 'registerpassword';
        if (!registerPasswordValue) {
            /*
            if (!registerError) registerError = {};
            registerError['registerpassword'] = 'Please enter your password';
            setRegisterError(registerError);
            return;
            */
            //let message = 'Please enter your password.';
            //let fieldName = 'registerpassword';
            setRegisterError({ ...registerError, [fieldName]: message });
            console.warn('registerError = ', registerError);
            return;
        } else {
            registerError[fieldName] = '';
            delete registerError[fieldName];
            setRegisterError(registerError);
        }

        message = 'Please enter your password again';
        fieldName = 'repeatpassword';
        if (!repeatPasswordValue) {
            /*
            if (!registerError) registerError = {};
            registerError['repeatpassword'] = 'Please enter your password again.';
            setRegisterError(registerError);
            */
            //let message = 'Please enter your password again.';
            //let fieldName = 'repeatpassword';
            setRegisterError({ ...registerError, [fieldName]: message });
            console.warn('registerError = ', registerError);
            return;
        } else {
            registerError[fieldName] = '';
            delete registerError[fieldName];
            setRegisterError(registerError);
        }

        if (registerPasswordValue !== repeatPasswordValue) {
            PopUpAlert(
                'Alert'
                //, 'Password and Re-password are not matched'
                , 'The passwords do not match.'
                , 'error'
            ).finally(() => {
                //setIsVisibleLoginDialog(true);
                setIsVisibleRegisterDialog(true);
            });
            return;
        }

        console.warn('isAgreeTermsRef?.current?.checked = ', isAgreeTermsRef?.current?.checked);
        if (isAgreeTermsRef.current.checked) {

            let payload = {
                  username: usernameValue
                , firstName: firstNameValue
                , lastName: lastNameValue
                , password: registerPasswordValue
            };
            if (referralCode) {
                payload.referralCode = referralCode
            }
            console.warn('payload = ', payload);

            let fetchConfig = {
                //method: 'PATCH'
                method: 'POST'
                , headers: {
                      //'Accept': 'application/json'
                      'Accept': '*/*'
                    , 'Content-Type': 'application/json'
                    //, Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
                }
                //, body: JSON.stringify(bodyObject)
                , body: JSON.stringify(payload)
            };
            console.warn('fetchConfig = ', fetchConfig);

            let fetchPath = `${ base_url }user/register-user`;
            console.warn('fetchPath = ', fetchPath);

            let fetchPromise = fetch(fetchPath, fetchConfig);
            fetchPromise
                .then(function(response) {
                    console.warn('response = ', response);
                    console.warn('response.ok = ', response.ok);
                    if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                        Swal.close();
                        return response.json();
                    } else {
                        //console.warn('response.text() = ', response.text());

                        return response.text().then(text => { throw new Error(text) })

                        /*
                        let err = 'err';
                        console.warn(err);
                        console.warn('err = ', err);
                        PopUpAlert('Alert', err.message, 'error');

                        return null;
                        */
                    }
                })

                // { success: true, message: "User Already Exists", result: {…} }
                // { success: false, message: "User Already Exists" }
                .then(function(responseJson) {
                    console.warn('responseJson = ', responseJson);

                    if (responseJson) {

                        //console.warn('responseJson.result = ', responseJson.result);
                        //console.warn('responseJson.result.existed = ', responseJson.result.existed);

                        console.warn('responseJson.success = ', responseJson.success);
                        if (responseJson.success) {

                            setIsRegisteredUser(true);
                            console.warn('isRegisteredUser = ', isRegisteredUser);

                            console.warn('responseJson.result = ', responseJson.result);
                            console.warn('typeof responseJson.result = ', typeof responseJson.result);

                            //getProfileDetails();
                            setUserDetails(responseJson.result);
                            console.warn('userDetails = ', userDetails);

                            /*
                            if (responseJson.result.existed) {
                                setIsExistedUser(true)
                            } else {
                                setIsExistedUser(false)
                            }
                            */
                            //if (responseJson.success) {
                                if (responseJson.message) {
                                    //PopUpAlert('Success!', responseJson.message, 'success')

                                    /*
                                    PopUpAlert(
                                        //'Success!'
                                        responseJson.message
                                        , 'Please check your email to activate your account.'
                                        , 'success'
                                    );
                                    */
                                    Swal.fire({
                                          //title: 'Success!'
                                          title: responseJson.message
                                        //, text: responseJson.message
                                        , text: 'Please check your email to activate your account.'
                                        //, icon: 'error'
                                        , icon: 'success'

                                        //, showCloseButton: true
                                        //, showConfirmButton: true
                                        //, closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
                                        //, confirmButtonText: 'Connect'
                                        //, buttonsStyling: false
                                        , customClass: {
                                            //  container: 'custom-container'
                                            //, popup: 'custom-popup custom-popup-add-email'
                                            //, confirmButton: "gold large stretch rounded"
                                            confirmButton: 'primary large'
                                        }
                                        , confirmButtonColor: 'var(--color-theme-primary)'
                                        //, iconColor: 'var(--color-theme-alert)'
                                        , iconColor: 'var(--color-theme-success)'
                                        //, input: 'email'
                                        //, inputLabel: 'Please add your email address'
                                        //, inputValue: email
                                        //, inputValue: 'service.account@wearefuturetech.com'
                                        //, inputPlaceholder: 'Enter your email address'
                                    }).then((result) => {
                                        // { isConfirmed: true, isDenied: false, isDismissed: false, value: true }
                                        console.warn(result);
                                        console.warn("result.isConfirmed = ", result.isConfirmed);
                                        console.warn("result.value = ", result.value);

                                        if (result.value) {

                                            setIsVisibleRegisterDialog(false);
                                            //console.warn('responseJson.result.username = ', responseJson.result.username);

                                            //let emailValue = emailRef.current.value;
                                            //console.warn("emailValue = ", emailValue);

                                            showLoginDialog();

                                            console.warn("usernameValue = ", usernameValue);
                                            console.warn("usernameRef = ", usernameRef);
                                            console.warn("usernameRef.current = ", usernameRef.current);
                                            usernameRef.current.value = usernameValue;
                                            console.warn("usernameRef.current.value = ", usernameRef.current.value);
                                        }
                                    });
                                }
                            //}

                        } else {

                            //setIsVisibleRegisterDialog(false);
                            //showLoginDialog();

                            //PopUpAlert(responseJson.message, errorObject.error, 'error')
                            //PopUpAlert('Alert!', responseJson.message, 'error');

                            /*
                            export const PopUpAlert = (title, text, icon) => {
                                return Swal.fire({
                                    title,
                                    text,
                                    icon,
                                    //confirmButtonColor: 'var(--color-theme-warning)',
                                    //confirmButtonColor: 'var(--color-theme-positive)',
                                    confirmButtonColor: 'var(--color-theme-primary)',
                                    iconColor: 'var(--color-theme-alert)',
                                    customClass: { confirmButton: 'primary large' }
                                });
                            };
                            */
                            Swal.fire({
                                  title: 'Alert!'
                                , text: responseJson.message
                                , icon: 'error'
                                //, showCloseButton: true
                                //, showConfirmButton: true
                                //, closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
                                //, confirmButtonText: 'Connect'
                                //, buttonsStyling: false
                                , customClass: {
                                    //  container: 'custom-container'
                                    //, popup: 'custom-popup custom-popup-add-email'
                                    //, confirmButton: "gold large stretch rounded"
                                    confirmButton: 'primary large'
                                }
                                , confirmButtonColor: 'var(--color-theme-primary)'
                                , iconColor: 'var(--color-theme-alert)'
                                //, input: 'email'
                                //, inputLabel: 'Please add your email address'
                                //, inputValue: email
                                //, inputValue: 'service.account@wearefuturetech.com'
                                //, inputPlaceholder: 'Enter your email address'
                            }).then((result) => {
                                // { isConfirmed: true, isDenied: false, isDismissed: false, value: true }
                                console.warn(result);
                                console.warn("result.isConfirmed = ", result.isConfirmed);
                                console.warn("result.value = ", result.value);

                                if (result.value) {

                                    setIsVisibleRegisterDialog(false);
                                    //console.warn('responseJson.result.username = ', responseJson.result.username);

                                    //let emailValue = emailRef.current.value;
                                    //console.warn("emailValue = ", emailValue);

                                    showLoginDialog();

                                    console.warn("usernameValue = ", usernameValue);
                                    console.warn("usernameRef = ", usernameRef);
                                    console.warn("usernameRef.current = ", usernameRef.current);
                                    usernameRef.current.value = usernameValue;
                                    console.warn("usernameRef.current.value = ", usernameRef.current.value);
                                }
                            });
                        }
                    }
                }).catch(err => {
                    console.warn(err);
                    console.warn('err = ', err);
                    console.warn('err.message = ', err.message);
                    console.warn('typeof err.message = ', typeof err.message);
                    let errorObject = JSON.parse(err.message);
                    console.warn('errorObject = ', errorObject);
                    /*
                    console.warn('err.response = ', err.response);
                    console.warn('err.response?.data = ', err.response?.data);
                    console.warn('err.response?.message = ', err.response?.message);
                    console.warn('err.response?.data?.message = ', err.response?.data?.message);
                    //let respErr = err.response?.data?.message || 'Please switch to polygon main network'
                    let respErr = err.response?.data?.message;
                    if (!respErr) respErr = err.response.toString();
                    PopUpAlert('Alert', respErr, 'error')
                    */
                    //PopUpAlert('Alert', err, 'error')
                    PopUpAlert(errorObject.message, errorObject.error, 'error')
                    //setConnectWalletBtnDisable(false)
                });
        } else {
            console.warn('isAgreeTermsRef?.current?.checked = ', isAgreeTermsRef?.current?.checked);
            let message = 'Please agree to the terms and conditions.';
            let fieldName = 'agree_terms';
            //registerError['agree_terms'] = 'Please agree to the terms and conditions.';
            //setRegisterError(registerError);
            setRegisterError({ ...registerError, [fieldName]: message });
            console.warn('registerError = ', registerError);
        } // if('on' = inputRef.value)
    };

    const handleLogin = async () => {
        console.log('handleLogin()');

        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const repassword = repasswordRef.current?.value;

        if (repassword && (password !== repassword)) {
            PopUpAlert(
                'Alert'
                , 'Password and Re-password are not matched'
                , 'error'
            ).finally(() => {
                setIsVisibleLoginDialog(true)
            });
            return;
        }
        
        fetchLogin({
              email: username
            , password: password
        });
    };

    let fetchLogin = async (bodyObject) => {
        console.warn('App: fetchLogin(bodyObject)', bodyObject);
        console.warn('App: history = ', history);

        setLoginModal(false);
        setIsLoadingLoginMetakeep(true);

        try {

            let localStorageKeyName = NAMES_CONSTANTS.USER_WALLET_DETAILS;
            console.log('App: fetchLogin(): localStorageKeyName = ', localStorageKeyName);

            let localStorageKeyNameUserAccessToken = NAMES_CONSTANTS.USER_ACCESS_TOKEN;
            console.log('App: fetchLogin(): localStorageKeyNameUserAccessToken = ', localStorageKeyNameUserAccessToken);

            const localStorageUserDetails = JSON.parse(localStorage.getItem(localStorageKeyName));
            console.log('App: fetchLogin(): localStorageUserDetails = ', localStorageUserDetails);

            console.log('App: fetchLogin(): axiosInstance = ', axiosInstance);
            axiosInstance
                //.post(`/user/authenticate${walletType === 'metakeep' ? '?type=METAKEEP' : ''}`, bodyObject)
                .post(`/user/authenticate?type=METAKEEP`, bodyObject)
                .then(async(res) => {
                    console.log('App: fetchLogin(): post(): res = ', res);

                    let userdata = res.data?.result;
                    console.log('App: fetchLogin(): post(): userdata = ', userdata);

                    let userAccessToken = userdata?.accessToken;
                    console.log('App: fetchLogin(): post(): userAccessToken = ', userAccessToken);

                    localStorage.setItem(localStorageKeyNameUserAccessToken, userAccessToken);

                    try {

                        let userWalletObject = { userId: userdata?.userId, email: userdata.email };
                        console.log('userWalletObject = ', userWalletObject);

                        let userWalletString =  JSON.stringify(userWalletObject, (key, value) =>
                            typeof value === 'bigint'
                            ? value.toString()
                            : value
                        );
                        console.log('userWalletString = ', userWalletString);

                        localStorage.setItem(
                              NAMES_CONSTANTS.USER_WALLET_DETAILS
                            //, JSON.stringify({ accounts, balance, userId: userdata?.userId, email: userdata.email })
                            //, JSON.stringify(userWalletObject)
                            , userWalletString
                        );
                        console.warn('App: fetchLogin(): post(): localStorage.getItem('+NAMES_CONSTANTS.USER_WALLET_DETAILS+') = ', localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));

                        //history.push("/my-assets");

                    } catch(err) {
                        console.error(err);
                        console.warn('err = ', err);
                        //PopUpAlert('Alert', err.message, 'error')
                        PopUpAlert(err.error, err.message, 'error')
                    };

                    //let userAccessToken = localStorage.getItem(localStorageKeyNameUserAccessToken);
                    //userAccessToken.current = localStorage.getItem(localStorageKeyNameUserAccessToken);
                    userAccessToken = localStorage.getItem(localStorageKeyNameUserAccessToken);
                    //console.log('               userAccessToken.current = ', userAccessToken.current);
                    console.warn('App: fetchLogin(): post(): userAccessToken = ', userAccessToken);
                    //alert('App: userAccessToken = '+ userAccessToken);
                    //if (userAccessToken.current) getProfileDetails();
                    if (userAccessToken) getProfileDetails();

                    console.warn('TO DO: Fix dispatch(setUser({');
                    /*
                    dispatch(setUser({ 
                        accounts,
                        balance,
                        userId: userdata?.userId,
                        email: userdata.email,
                        network: walletType,
                    }))
                    dispatch(increment());
                    dispatch(details({ accounts, balance }));
                    */

                    //PopUpAlert('Connection Successful', 'You are now connected', 'success')
                    PopUpConfirmation('Connection Successful', 'You are now connected', 'success')
                        .then(result => {
                            setLoginModal(false);
                            getBalanceToken();
                            if (referralCode) {
                                dispatch(referralCodeUpdate(null))
                            }
                            history.push("/my-assets");
                        });

                }).catch(err => {
                    //console.log(err);
                    //console.log('err = ', err);
                    console.log('err.response = ', err.response);
                    console.log('err.response?.data = ', err.response?.data);
                    console.log('err.response?.message = ', err.response?.message);
                    console.log('err.response?.data?.message = ', err.response?.data?.message);
                    //let respErr = err.response?.data?.message || 'Please switch to polygon main network'
                    let respErr = err.response?.data?.message;
                    if (!respErr) respErr = err.response.toString();
                    //PopUpAlert('Alert', respErr, 'error')
                    PopUpAlert(
                        //'Alert'
                          err.response?.data?.message || 'Alert'
                        , err.response?.data?.error
                        , 'error'
                    );
                    setConnectWalletBtnDisable(false)
                }
            )  // axiosInstance.post()

        } catch (err) {
            console.log('err = ', err);
            Swal.hideLoading()
        } finally {
            console.log('fetchLogin() ... finally {');
            console.log('Swal.hideLoading()');
            Swal.hideLoading()
            console.log('setLoginModal(false)');
            setLoginModal(false);
            console.log('setConnectWalletBtnDisable(false)');
            setConnectWalletBtnDisable(false);
            console.log('setIsLoadingLoginMetakeep(false)');
            setIsLoadingLoginMetakeep(false);
        }
    }; // fetchLogin()

    let metakeepLogin = async (bodyObject) => {
        console.log('App: metakeepLogin(bodyObject)', bodyObject);

        setLoginModal(false);
        setIsLoadingLoginMetakeep(true);

        let metakeepConfig = {
            appId: process.env.REACT_APP_METAKEEP_API_ID
            , user: {
                email: bodyObject.email
            }
        };
        console.log('App: fetchLogin(): metakeepConfig = ', metakeepConfig);

        let metakeepConfigAll = {
              appId: process.env.REACT_APP_METAKEEP_API_ID
            , chainId: process.env.REACT_APP_CHAIN
            , user: {
                email: bodyObject.email
            }
            , rpcNodeUrls: {
                80001: process.env.REACT_APP_METAMASK_NETWORK_RPC_URL
            }
        };
        console.log('App: fetchLogin(): metakeepConfigAll = ', metakeepConfigAll);

        let accounts, balance;

        try {

            const metakeepSdk = new MetaKeep(metakeepConfigAll);
            console.log('App: fetchLogin(): metakeepSdk = ', metakeepSdk);

            let wallet = await metakeepSdk.getWallet();
            console.log('App: fetchLogin(): wallet = ', wallet);

            let localStorageKeyNameUserAccessToken = NAMES_CONSTANTS.USER_ACCESS_TOKEN;
            console.log('App: fetchLogin(): localStorageKeyNameUserAccessToken = ', localStorageKeyNameUserAccessToken);

            let localStorageKeyName = NAMES_CONSTANTS.USER_WALLET_DETAILS;
            console.log('App: fetchLogin(): localStorageKeyName = ', localStorageKeyName);

            const localStorageUserDetails = JSON.parse(localStorage.getItem(localStorageKeyName));
            console.log('App: fetchLogin(): localStorageUserDetails = ', localStorageUserDetails);

            try {
                //setLoginModal(false);
                //getBalanceToken();

                //provider = await metaKeepSdk.ethereum;
                const provider = await metakeepSdk.ethereum;
                console.log('App: fetchLogin(): provider = ', provider);

                //await connectWallet(provider, 'metakeep', bodyObject);
                const web3 = new Web3(provider);
                console.log('App: fetchLogin(): web3 = ', web3);

                // Get accounts  
                const web3Accounts = await web3.eth.getAccounts();
                console.log('App: fetchLogin(): web3Accounts = ', web3Accounts);

                //const accounts = await web3.eth.getAccounts();
                accounts = await web3.eth.getAccounts();
                console.log('App: fetchLogin(): accounts = ', accounts);

                // const erc20Contract = await new web3.eth.Contract(contract_erc20token_abi, process.env.REACT_APP_ERC20_ADDRESS_DEV);
                balance = await web3.eth.getBalance(accounts[0]);
                console.log('App: fetchLogin(): balance = ', balance);
                console.log('App: fetchLogin(): typeof balance = ', typeof balance);

                localStorage.setItem('tokenbalance = ', balance);
                console.log('App: fetchLogin(): localStorage.getItem(\'tokenbalance\') = ', localStorage.getItem('tokenbalance'));

                const updatedBalanceUserDetails = { ...localStorageUserDetails, balance };
                console.log('App: fetchLogin(): updatedBalanceUserDetails = ', updatedBalanceUserDetails);

            } catch (error) {
                console.log('App: fetchLogin(): error = ', error);
            }

            //localStorage.setItem(localStorageKeyName, JSON.stringify(updatedBalanceUserDetails));
            //console.log('       localStorage.getItem(\''+localStorageKeyName+'\') = ', localStorage.getItem(localStorageKeyName));

            let walletType = 'metakeep';
            localStorage.setItem('network', walletType);
            console.log('App: fetchLogin(): walletType = ', walletType);

            console.log('App: fetchLogin(): axiosInstance = ', axiosInstance);
            axiosInstance
                .post(`/user/authenticate${walletType === 'metakeep' ? '?type=METAKEEP' : ''}`, bodyObject)
                .then(async(res) => {
                    console.log('App: fetchLogin(): post(): res = ', res);

                    let userdata = res.data?.result;
                    console.log('App: fetchLogin(): post(): userdata = ', userdata);

                    let userAccessToken = userdata?.accessToken;
                    console.log('App: fetchLogin(): post(): userAccessToken = ', userAccessToken);

                    localStorage.setItem(localStorageKeyNameUserAccessToken, userAccessToken);

                    try {

                        console.log('App: fetchLogin(): post(): accounts = ', accounts, typeof accounts);
                        console.log('App: fetchLogin(): post(): balance = ', balance, typeof balance);
                        console.log('App: fetchLogin(): post(): JSON.stringify({ accounts } = ', JSON.stringify({ accounts }));
                        try {
                            console.log('App: fetchLogin(): post(): JSON.stringify({ balance } = ', JSON.stringify({ balance }));
                        } catch(err) {
                            console.log(err);
                            console.log('err = ', err);
                        }
                        console.log('App: fetchLogin(): post(): JSON.stringify({ userId: userdata?.userId } = ', JSON.stringify({ userId: userdata?.userId }));
                        console.log('App: fetchLogin(): post(): JSON.stringify({ email: userdata.email } = ', JSON.stringify({ email: userdata.email }));

                        let userWalletObject = { accounts, balance, userId: userdata?.userId, email: userdata.email };
                        console.log('userWalletObject = ', userWalletObject);

                        let userWalletString =  JSON.stringify(userWalletObject, (key, value) =>
                            typeof value === 'bigint'
                                ? value.toString()
                                : value
                        );
                        console.log('userWalletString = ', userWalletString);

                        localStorage.setItem(
                              NAMES_CONSTANTS.USER_WALLET_DETAILS
                            //, JSON.stringify({ accounts, balance, userId: userdata?.userId, email: userdata.email })
                            //, JSON.stringify(userWalletObject)
                            , userWalletString
                        );
                        console.log('App: fetchLogin(): post(): localStorage.getItem('+NAMES_CONSTANTS.USER_WALLET_DETAILS+') = ', localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));

                    } catch(err) {
                        console.log(err);
                        console.log('err = ', err);
                        PopUpAlert('Alert', err.message, 'error')
                    };

                    //let userAccessToken = localStorage.getItem(localStorageKeyNameUserAccessToken);
                    //userAccessToken.current = localStorage.getItem(localStorageKeyNameUserAccessToken);
                    userAccessToken = localStorage.getItem(localStorageKeyNameUserAccessToken);
                    //console.log('               userAccessToken.current = ', userAccessToken.current);
                    console.log('App: fetchLogin(): post(): userAccessToken = ', userAccessToken);
                    //alert('App: userAccessToken = '+ userAccessToken);
                    //if (userAccessToken.current) getProfileDetails();
                    if (userAccessToken) getProfileDetails();

                    console.log('TO DO: Fix dispatch(setUser({');
                    /*
                    dispatch(setUser({ 
                        accounts,
                        balance,
                        userId: userdata?.userId,
                        email: userdata.email,
                        network: walletType,
                    }))
                    dispatch(increment());
                    dispatch(details({ accounts, balance }));
                    */

                    //PopUpAlert('Connection Successful', 'You are now connected', 'success')
                    PopUpConfirmation('Connection Successful', 'You are now connected', 'success')
                        .then(result => {
                            setLoginModal(false);
                            getBalanceToken();
                            if (referralCode) {
                                dispatch(referralCodeUpdate(null))
                            }
                        });

                }).catch(err => {
                    console.log(err);
                    console.log('err = ', err);
                    console.log('err.response = ', err.response);
                    console.log('err.response?.data = ', err.response?.data);
                    console.log('err.response?.message = ', err.response?.message);
                    console.log('err.response?.data?.message = ', err.response?.data?.message);
                    //let respErr = err.response?.data?.message || 'Please switch to polygon main network'
                    let respErr = err.response?.data?.message;
                    if (!respErr) respErr = err.response.toString();
                    PopUpAlert('Alert', respErr, 'error')
                    setConnectWalletBtnDisable(false)
                }
            )  // axiosInstance.post()

        } catch (err) {
            console.log('err = ', err);
            Swal.hideLoading()
        } finally {
            console.log('fetchLogin() ... finally {');
            console.log('Swal.hideLoading()');
            Swal.hideLoading()
            console.log('setLoginModal(false)');
            setLoginModal(false);
            console.log('setConnectWalletBtnDisable(false)');
            setConnectWalletBtnDisable(false);
            console.log('setIsLoadingLoginMetakeep(false)');
            setIsLoadingLoginMetakeep(false);
        }
    }; // fetchLogin()

    /*
    //const handleResetPassword = async () => {
    //const handleResetPassword = () => {
    const handleResetPassword = (e) => {
        console.log('handleResetPassword(e)', e);

        const username = usernameRef.current.value;

        fetchResetPassword({
              email: username
        });
        setIsVisibleResetPasswordDialog(false);
    }
    */

    const handleCloseLoginDialog = () => {
        setIsVisibleLoginDialog(false)
        setIsValidatedUser(false)
        setIsExistedUser(false)
    };

    const handleCloseResetPasswordDialog = () => {
        setIsVisibleResetPasswordDialog(false)
    };

    const handleCloseRegisterDialog = () => {
        setIsVisibleRegisterDialog(false);
    };

    const forgotPasswordSubmitHandler = (e) => {
        console.log('forgotPasswordSubmitHandler(e)', e);

        e.preventDefault();

        let formElement = e.target;

        //const formElements = formElement.elements;
        //for (let i = 0; i < formElements.length; i++) {
        //    console.log('formElements['+i+'] = ', formElements[i]);
        //}

        const emailInputElement = formElement.elements['email'];
        console.log('emailInputElement = ', emailInputElement);

        let emailAddress = emailInputElement.value;
        console.log('emailAddress = ', emailAddress);

        //let emailValue = emailRef.current.value;

        if (!emailAddress) {
            let forgotPasswordError = {};
            forgotPasswordError['email'] = 'Please enter your email address';
            //formIsValid = false;
            setForgotPasswordError(forgotPasswordError);
            return;
        }

        //const { data: user } = await axiosInstance.post(`user/check-exist`, { username: usernameValue });

        if (
            !emailAddress
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        ) {
            Swal.fire("Alert", "Enter a valid email address.", "error");
            return false;
        }

        if (emailAddress !== "") {

            /*
            const payload = { email: email };
            axiosInstance
                .post("/user/forgot-password", payload)
                .then((res) => {
                    Swal.fire(
                          "Info"
                        //, "Check your email for password reset link"
                        , res.data.message
                        , "info"
                    );
                    history.push("/sign-in");
                })
                .catch((err) => Swal.fire("Alert", err.response.data.message, "error"));
            */
            fetchForgotPassword(emailAddress);

        } else {
            Swal.fire("Alert", "Please enter your email address", "error");
        }
    };

    let fetchForgotPassword = (emailAddress) => {

        const bodyObject = { email: emailAddress };

        let fetchConfig = {
            //method: 'PATCH'
            method: 'POST'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: JSON.stringify(bodyObject)
        };

        let fetchPath = `${base_url}user/forgot-password`;
        let fetchPromise = fetch(fetchPath, fetchConfig);
        fetchPromise
            .then(function(response) {
                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    /*
                    Swal.fire(
                          "Info"
                        //, "Check your email for password reset link"
                        , res.data.message
                        , "info"
                    );
                    history.push("/sign-in");
                    */
                    return response.json();
                }
            })
            .then(function(responseJson) {
                console.log('responseJson = ', responseJson);

                console.log('responseJson?.success = ', responseJson?.success);
                console.log('responseJson?.message = ', responseJson?.message);

                //PopUpAlert(responseJson.message, errorObject.error, 'error')
                //PopUpAlert(responseJson.message, errorObject.error, 'warning')
                //PopUpAlert('Alert', responseJson.message, 'warning')
                PopUpConfirmation('Success', responseJson.message, 'success')

                /*
                if (responseJson.success === false) {

                    if (responseJson.message.contains("Authorization token invalid")) {
                        PopUpAlert('Failure', responseJson.message, 'error')
                            .then(
                                res => {
                                    //history.push('/order-userDetails');
                                    window.location.reload();
                                }
                            );
                    } else {
                        PopUpAlert('Failure', responseJson.message, 'error').then(
                            res => {
                                //history.push('/order-userDetails');
                                window.location.reload();
                            }
                        );
                    }
                }
                */
            });
    }; // fetchUpdateProfile()

    const getCart = async () => {
        console.log("getCart()");
        const config = getConfig();
        const response = await axiosInstance.get('/carts?limit=100&page=1', config);
        console.log("response", response);
        console.log("response?.data", response?.data);
        if (response.data) {
            setCartAssetsList(response.data?.result?.assets);
            setCartDetail(response.data?.result);
            //dispatch(countCart(response.data?.result?.totalItems));
        }
    };

    const keyDownPassword = (e) => {
        console.log('keyDownPassword(e)');
        if (e.key === 'Enter') {
            console.log('e.key = ', e.key);
            console.error(e.key, 'key');
            handleLogin();
        }
    };

    const keyDownUsername = (e) => {
        console.log('keyDownUsername(e)');

        if (e.key === 'Enter') {
            console.log('e.key = ', e.key);
            console.error(e.key, 'key');

            if (isValidatedUser) {
                handleLogin();
            } else {
                validateUserExist();
            }
        }
    };

    const disconnectUser = () => {
        console.log('disconnectUser()');

        //if (e) e.preventDefault();

        /*
        dispatch(setUser({ 
            account: null,
            balance: 0,
            userId: null,
            email: null,
            network: null,
        }))

        dispatch(decrement());
        */

        localStorage.removeItem('magicprovide')
        localStorage.removeItem('tokenbalance');
        localStorage.removeItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
        localStorage.removeItem(NAMES_CONSTANTS.USER_WALLET_DETAILS);
        localStorage.removeItem('network');

        //PopUpAlert('Success', 'Wallet Disconnected', 'success');

        //history.push('/');
        //window.location.reload();
    };

    const clickLogoutUser = async (e) => {
        console.warn('clickLogoutUser(e)', e);

        e.preventDefault();

        //dispatch(setUser({}))
        //dispatch(decrement());

        localStorage.removeItem('magicprovide')
        localStorage.removeItem('tokenbalance');
        localStorage.removeItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
        localStorage.removeItem(NAMES_CONSTANTS.USER_WALLET_DETAILS);
        localStorage.removeItem('network');

        //PopUpAlert('Success', 'Wallet Disconnected', 'success');
        PopUpConfirmation('Success', 'Wallet Disconnected', 'success');

        setTimeout(() => {
            //history.push('/');
            //window.location.reload();
            window.location.href = '/'
        }, 10);
    };

    const logoutUser = async () => {
        console.warn('logoutUser()');

        localStorage.removeItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
        localStorage.removeItem(NAMES_CONSTANTS.USER_WALLET_DETAILS);
        //localStorage.removeItem('magicprovide')
        //localStorage.removeItem('tokenbalance');
        //localStorage.removeItem('network');

        //PopUpAlert('Success', 'Wallet Disconnected', 'success');
        //PopUpConfirmation('Success', 'Wallet Disconnected', 'success');
        PopUpConfirmation('Success', 'Wallet Disconnected', 'success').then(function() { window.location.href = '/' });

        /*
        setTimeout(() => {
            //history.push('/');
            //window.location.reload();
            window.location.href = '/'
        }, 10);
        */
    };

    /*
    let loginFunctionReferences = {
        connectWalletFunction = { handleConnectWallet }
        clickConnectWalletFunction = { clickConnectWalletButton }
        chooseWalletType = { chooseWalletType }
        userDetails = { userDetails }
        //clickSystemWallet = { clickSystemWallet }
        showRegisterDialog = { showRegisterDialog }
        showLoginDialog = { showLoginDialog }
    };
    */

    const clickBecomeInvestor = async function(event) {
        console.warn("clickBecomeInvestor()", event);
        console.warn("event.preventDefault = " + event.preventDefault);
        event.preventDefault();
        console.warn("event.stopPropagation = " + event.stopPropagation);
        event.stopPropagation();

        //props.chooseWalletType();
        //props.showLoginDialog();
        //props.showRegisterDialog();
        showRegisterDialog();

        return false;
    };

    const loginRegisterFunctions = {
          connectWalletFunction: handleConnectWallet
        , clickConnectWalletFunction: clickConnectWalletButton
        , chooseWalletType: chooseWalletType
        , userDetails: userDetails
        //clickSystemWallet: clickSystemWallet
        , showRegisterDialog: showRegisterDialog
        , showLoginDialog: showLoginDialog
        , logoutUser: clickLogoutUser
        , clickBecomeInvestor: clickBecomeInvestor
    };

    const handleChangeRegisterPassword = (e) => {
        console.warn('handleChangeRegisterPassword('+e+')');

        console.warn('e.target.name = ', e.target.name);
        console.warn('e.target.value = ', e.target.value);

        setRegisterObject({ ...registerObject, [e.target.name]: e.target.value });
    };

    const handleChangeAgreeTerms = (e) => {
        console.warn('handleChangeAgreeTerms('+e+')');

        console.warn('e.target.name = ', e.target.name);
        console.warn('e.target.value = ', e.target.value);
        console.warn('e.target.checked = ', e.target.checked);

        //setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
        //setError({ ...error, [e.target.name]: '' })
        //handleUserDetailsChangeNameValue(e.target.name, e.target.value);
        //setIsAgreeTerms(e.target.value);
        //setIsAgreeTerms(e.target.checked);
        //setError({ ...error, [e.target.name]: '' })

        //registerError['agree_terms'] = 'Please Agree to the terms and conditions.';
        if (e.target.checked) {
            setRegisterError({ ...registerError, [e.target.name]: '' });
            setIsAgreeTerms(true);
        } else {
            //registerError['agree_terms'] = 'Please Agree to the terms and conditions.';
            setRegisterError({ ...registerError, [e.target.name]: 'Please agree to the terms and conditions.' });
            setIsAgreeTerms(false);
            //setRegisterError(registerError);
        }
    };

    return (

        <>
        <Modal
            visible = { isVisibleLoginDialog }
            onClose = { handleCloseLoginDialog }
            >
            <div>
                <h3
                    style = {{ 
                          textAlign: 'left'
                        , fontSize: '40px'
                        , fontWeight: 600
                    }}
                    >
                    Sign In
                </h3>
                <p
                    className="title"
                    style = {{
                        textAlign: 'left'
                        , fontSize: 16
                        , marginTop: 10
                        , fontWeight: 500
                        , color: '#777E90'
                    }}>
                    Enter your credentials to log in to your account.
                </p>

                {/*
                    !isExistedUser && isValidatedUser
                    &&  
                        <p className="title" style={{textAlign: 'left', fontSize: 16, marginTop: 10, fontWeight: 500, color: '#777E90'}}>Welcome to RealSplit, enter a password to finish your registration!</p>
                */}
                <div className = { "editable" } style = {{ marginBlock: 20 }}>
                    <div className = { "input-box" } style = {{ marginBottom: 8 }}>
                        <input
                            type = "text"
                            name = "username"
                            //value = 'service.account@wearefuturetech.com'
                            ref = { usernameRef }
                            style = {{ width: '100%' }}
                            placeholder = "name@email.com"
                            onKeyDown = {(e) => keyDownUsername(e)}
                            />
                    </div>

                    {/*
                        isValidatedUser &&  
                    */}
                        <div className = { "input-box" }>
                            <input
                                //type = "password"
                                type = { loginPasswordVisibility ? "text" : "password" }
                                ref = { passwordRef }
                                style = {{ width: '100%' }}
                                name = "password"
                                //value = ''
                                placeholder="**********"
                                onKeyDown={(e) => keyDownPassword(e)}
                                />
                                {
                                    loginPasswordVisibility
                                    ? (
                                        <VisibilityIcon
                                            style={{ margin: "0px 8px 0px -32px" }}
                                            onClick={() => handleChangeLoginPasswordVisibility()}
                                            />
                                    ) : (
                                        <VisibilityOffIcon
                                            style={{ margin: "0px 8px 0px -32px" }}
                                            onClick={() => handleChangeLoginPasswordVisibility()}
                                            />
                                    )
                                }
                        </div>
                    {/*
                    }
                    */}

                    {/*
                        !isExistedUser
                        && isValidatedUser
                        &&  
                            <div className = { "input-box" } style={{marginTop: 8}}>
                                <input
                                    type = "password"
                                    ref = { repasswordRef }
                                    style = {{ width: '100%' }}
                                    name = "repassword"
                                    //value = ''
                                    placeholder = "**********"
                                    //onKeyDown={(e) => keyDownPassword(e)}
                                    />
                            </div>
                    */}
                   
                    <div>
                        {
                            loginError?.username
                            && <div
                                //className = { styles.errorMsg }
                                className = 'errorMsg'
                                >{ loginError?.username }</div>
                        }
                    </div>

                </div>

                {/*
                    !isValidatedUser
                    ? <button
                        //className = 'button-login button gold large rounded'
                        className = 'primary'
                        style = {{ marginBottom: 10 }}
                        onClick = { validateUserExist }
                        >
                        <span>Continue</span>
                    </button>
                    : <button
                        //className = 'button-login button gold large rounded'
                        className = 'primary'
                        style = {{ marginBottom: 10 }}
                        onClick = { handleLogin }
                        >
                        {
                            isLoadingLoginMetakeep
                            ? <CircularProgress size={25} />
                            //: <span>{ isExistedUser ? 'Log in': 'Register' }</span>
                            : <span>Log In</span>
                        }
                    </button>
                */}
                <button
                    className = 'primary'
                    style = {{ marginBottom: 10 }}
                    onClick = { handleLogin }
                    >
                    <span>Log In</span>
                </button>
              
                <button
                    //className="button-login button large rounded transparent"
                    //className = 'theme-cancel large rounded transparent'
                    //className = 'theme-cancel large'
                    className = 'primary-inverse large'
                    style = {{ marginLeft: '20px' }}
                    onClick = { handleCloseLoginDialog }
                    >
                    <span>Cancel</span>
                </button>
            </div>

            <div style = {{ paddingTop: '18px' }}>
                <button
                    //className = 'theme-primary rounded medium '
                    className = 'primary-inverse rounded medium '
                    //className = 'link'
                    //style = {{ marginLeft: '20px' }}
                    style = {{ padding: '10px 20px' }}
                    //onClick = { clickForgotPassword }
                    onClick = { clickForgotPassword }
                    >
                    <span>Forgot Password?</span>
                </button>
                {/*
                <Link to='' onClick = { clickForgotPassword }>Forgot Password?</Link>
                */}
            </div>

        </Modal>


        <Modal
            visible = { isVisibleRegisterDialog }
            onClose = { handleCloseRegisterDialog }
            >
            <div>

                <h3
                    style = {{ 
                          textAlign: 'left'
                        , fontSize: '40px'
                        , fontWeight: 600
                    }}
                    >
                    Register
                </h3>

                {/*
                <p className="title" style={{textAlign: 'left', fontSize: 16, marginTop: 10, fontWeight: 500, color: '#777E90'}}>Enter your email to connect to <strong>your system wallet</strong></p>
                {
                    !isExistedUser && isValidatedUser
                    &&  
                        <p className="title" style={{textAlign: 'left', fontSize: 16, marginTop: 10, fontWeight: 500, color: '#777E90'}}>Welcome to RealSplit, enter a password to finish your registration!</p>
                }
                */}

                <div className = { "editable" } style = {{ marginBlock: 20 }}>

                    <div className = { "input-box" } style = {{ marginBottom: 8 }}>
                        {/*
                            isRegisteredUser
                            ?
                                <span>{ userDetails?.firstName }</span>
                                //`userDetails?.firstName: userDetails?.firstName`
                            :
                        */}
                                <input
                                    type = "text"
                                    name = "firstName"
                                    ref = { firstNameRef }
                                    style = {{ width: '100%' }}
                                    placeholder = "First Name"
                                    //onKeyDown = {(e) => keyDownUsername(e)}
                                    />
                        {/*
                        }
                        */}
                    </div>

                    <div className = { "input-box"} style = {{ marginBottom: 8 }}>
                        {/*
                            isRegisteredUser
                            ?
                                <span>{ userDetails?.lastName }</span>
                            :
                        */}
                                <input
                                    type = "text"
                                    name = "lastName"
                                    ref = { lastNameRef }
                                    style = {{ width: '100%' }}
                                    placeholder = "Last Name"
                                    //onKeyDown = {(e) => keyDownUsername(e)}
                                    />
                        {/*
                        }
                        */}
                    </div>

                    <div
                        style = {{ marginBottom: 8 }}
                        >
                        <div
                            className = { "input-box"}
                            //style = {{ marginBottom: 8 }}
                            >
                            {/*
                                isRegisteredUser
                                ?
                                    <span>{ userDetails?.email }</span>
                                :
                            */}
                                    <input
                                        type = "text"
                                        name = "username"
                                        //value = 'service.account@wearefuturetech.com'
                                        ref = { emailRef }
                                        style = {{ width: '100%' }}
                                        placeholder = "name@email.com"
                                        onKeyDown = {(e) => keyDownUsername(e)}
                                        value =  { registerObject?.username }
                                        onChange = { handleChangeRegisterPassword }
                                        />
                            {/*
                            }
                            */}
                        </div>
                        <div>
                            {
                                registerError?.username
                                && <div
                                    //className = { styles.errorMsg }
                                    className = 'error-msg'
                                    >{ registerError?.username }</div>
                            }
                        </div>
                    </div>

                    {/*
                        isRegisteredUser &&  
                    */}
                    <div
                        style = {{ marginBottom: 8 }}
                        >
                        <div className = { "input-box" }>
                            <input
                                //type = "password"
                                type = { registerPasswordVisibility ? "text" : "password" }
                                ref = { registerPasswordRef }
                                style = {{ width: '100%' }}
                                name = "registerpassword"
                                onChange = { handleChangeRegisterPassword }
                                //value = ''
                                value =  { registerObject?.registerpassword }
                                //placeholder="**********"
                                placeholder="Enter a password"
                                onKeyDown={(e) => keyDownPassword(e)}
                                />
                                {
                                    registerPasswordVisibility
                                    ? (
                                        <VisibilityIcon
                                            style={{ margin: "0px 8px 0px -32px" }}
                                            onClick={() => handleChangeRegisterPasswordVisibility()}
                                            />
                                    ) : (
                                        <VisibilityOffIcon
                                            style={{ margin: "0px 8px 0px -32px" }}
                                            onClick={() => handleChangeRegisterPasswordVisibility()}
                                            />
                                    )
                                }
                        </div>
                        <div>
                            {
                                registerError?.registerpassword
                                && <div
                                    className = 'error-msg'
                                    >{ registerError?.registerpassword }</div>
                            }
                        </div>
                    </div>
                    {/*
                    }
                    */}

                    {/*
                    {
                        //!isExistedUser
                        //&& isValidatedUser
                        isRegisteredUser
                        &&  
                    */}
                    <div
                        style = {{ marginBottom: 8 }}
                        >
                        <div className = { "input-box" } style={{marginTop: 8}}>
                            <input
                                //type = "password"
                                type = { repeatRegisterPasswordVisibility ? "text" : "password" }
                                ref = { repeatPasswordRef }
                                style = {{ width: '100%' }}
                                name = "repeatpassword"
                                value =  { registerObject?.repeatpassword }
                                onChange = { handleChangeRegisterPassword }
                                //onChange = { handleChangeRepeatPassword }
                                //value = ''
                                //placeholder = "**********"
                                placeholder="Repeat the password"
                                //onKeyDown={(e) => keyDownPassword(e)}
                                />
                                {
                                    repeatRegisterPasswordVisibility
                                    ? (
                                        <VisibilityIcon
                                            style={{ margin: "0px 8px 0px -32px" }}
                                            onClick={() => handleChangeRepeatRegisterPasswordVisibility()}
                                            />
                                    ) : (
                                        <VisibilityOffIcon
                                            style={{ margin: "0px 8px 0px -32px" }}
                                            onClick={() => handleChangeRepeatRegisterPasswordVisibility()}
                                            />
                                    )
                                }
                        </div>
                        <div>
                            {
                                registerError?.repeatpassword
                                && <div
                                    className = 'error-msg'
                                    >{ registerError?.repeatpassword }</div>
                            }
                        </div>
                    </div>
                    {/*
                    }
                    */}

                    <div
                        style = {{ marginBottom: 8 }}
                        >
                        <div className = { "table" } style = {{ maxWidth: "600px", margin: "10px auto 5px auto" }}>
                            <div className = { "row" }>
                                <div className = { "cell" }>
                                    <input
                                        type = "checkbox"
                                        id = "agree_terms"
                                        name = "agree_terms"
                                        ref = { isAgreeTermsRef }
                                        value = { isAgreeTerms }
                                        onChange = { handleChangeAgreeTerms }
                                        //onChange = () => { setIsAgreeTerms(this.value) }
                                        //onChange = { () => setIsAgreeTerms(this.value) }
                                        style = {{
                                              verticalAlign: "middle"
                                            , border: "2px solid #E6E8EC"
                                            , borderRadius: "5px"
                                            , width: '30px'
                                            , height: '30px'
                                        }}
                                        />
                                </div>
                                <div className = { "cell" }>
                                    <label
                                        for = "agree_terms"
                                        style = {{
                                            paddingLeft: "10px"
                                            , userSelect: "none"
                                            , paddingBottom: "0px"
                                            , fontSize: ".9em"
                                        }}
                                        >
                                        By clicking here you understand and agree our <Link to="/terms-and-conditions" target = '_blank'>Terms of Service</Link> and to our <Link to="/privacy-policy" target = 'new'>Privacy Policy</Link>.
                                        Please read these carefully before using the Site.
                                    </label>
                                    {/*
                                        error?.agree_terms
                                        && <div className = 'error-msg'>{ error?.agree_terms }</div>
                                    */}
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                registerError?.agree_terms
                                && <div
                                    className = 'error-msg'
                                    >{ registerError?.agree_terms }</div>
                            }
                        </div>
                    </div>

                </div>

                {
                    !isRegisteredUser

                    ? <button
                        className = 'primary'
                        style = {{ marginBottom: 10 }}
                        onClick = { registerUser }
                        >
                        <span>Continue</span>
                    </button>

                    : <button
                        className = 'primary'
                        style = {{ marginBottom: 10 }}
                        onClick = { handleLogin }
                        >
                        <span>Save Password</span>
                    </button>
                }

                {/*
                <button
                    //className = 'button-login button gold large rounded'
                    className = 'primary'
                    style = {{ marginBottom: 10 }}
                    onClick = { registerUser }
                    >
                    <span>Continue</span>
                </button>
                */}
              
                <button
                    //className="button-login button large rounded transparent"
                    //className = 'theme-cancel large rounded transparent'
                    //className = 'theme-cancel large'
                    className = 'primary-inverse large'
                    style = {{ marginLeft: '20px' }}
                    onClick = { handleCloseRegisterDialog }
                    >
                    <span>Cancel</span>
                </button>
            </div>

            <div style = {{ paddingTop: '18px' }}>
                <div
                    style = {{ paddingRight: '20px', fontSize: '.9em' }}
                    >
                    Already a member?
                </div>
                <button
                    //className = 'theme-primary rounded medium '
                    className = 'primary-inverse rounded medium '
                    //className = 'link'
                    //style = {{ marginLeft: '20px' }}
                    style = {{
                        padding: '10px 20px'
                    }}
                    //onClick = { clickForgotPassword }
                    //onClick = { clickForgotPassword }
                    onClick = { showLoginDialog }
                    >
                    <span>Log In</span>
                </button>
                {/*
                <Link to='' onClick = { clickForgotPassword }>Forgot Password?</Link>
                */}
            </div>

        </Modal>

        <Modal
            visible = { isVisibleResetPasswordDialog }
            onClose = { handleCloseResetPasswordDialog }
            >
            <form
                onSubmit = { forgotPasswordSubmitHandler }>
                <div>
                
                    <h3
                        style = {{ 
                              textAlign: 'left'
                            , fontSize: '40px'
                            , fontWeight: 600
                        }}>
                        Reset Your Password
                    </h3>

                    <p
                        className="title"
                        style = {{
                            textAlign: 'left'
                            , fontSize: 16
                            , marginTop: 10
                            , fontWeight: 500
                            , color: '#777E90'
                        }}
                        >
                        Enter your email to reset your password.
                    </p>
                    {
                        !isExistedUser && isValidatedUser
                        &&  
                            <p className="title" style={{textAlign: 'left', fontSize: 16, marginTop: 10, fontWeight: 500, color: '#777E90'}}>Welcome to RealSplit, enter a password to finish your registration!</p>
                    }
                    <div className = { "editable" } style={{marginBlock: 20}}>

                        <div
                            className = { "input-box"}
                            style = {{ marginBottom: 8 }}
                            >
                            <input
                                type = "email"
                                name = "email"
                                ref = { usernameRef }
                                style = {{ width: '100%' }}
                                placeholder = "name@email.com"
                                />
                        </div>

                        <div>
                            {
                                forgotPasswordError?.email
                                && <div
                                    className = 'errorMsg'
                                    >{ forgotPasswordError?.email }</div>
                            }
                        </div>

                    </div>

                    <button
                        type = 'submit'
                        //className = 'button-login button gold large rounded'
                        className = 'primary'
                        style = {{ marginBottom: 10 }}
                        //onClick = { validateUserExist }
                        //onClick = { handleResetPassword }
                        >
                        <span>Reset Password</span>
                    </button>
                  
                    <button
                        //className="button-login button large rounded transparent"
                        //className = 'theme-cancel large rounded transparent'
                        className = 'theme-cancel large'
                        style = {{ marginLeft: '20px' }}
                        onClick = { handleCloseResetPasswordDialog }
                        >
                        <span>Cancel</span>
                    </button>
                </div>
            </form>

        </Modal>

        {/*
        <MobileNav
            isOpen = { isShowMobileNav }
            closeModal = { () => setIsShowMobileNav(false) }
            //headerNav = { headerNav }
            //handleDisconnectFunction = { handleDisconnect }
            loginRegisterFunctions = { loginRegisterFunctions }
            />
        */}

        {
            loginModal
            && <LoginModal close = { () => loginClose() }
                //loginMethod = { magicSocialLoginMethods }
                connectWallet = { handleConnectWallet }
                //connectWallet = { props.connectWalletFunction }
                //connectWallet = { connectWalletFunction }
                connectDisable = { connectWalletBtnDisable }
                />
        }

        {
            loading 
            ? <div id="preload">
                  <CircularProgress />
              </div>
            : null
        }

        {/*
        <Router>
        */}
            <Switch>

                {/* Dermot moved this to AppWrapper
                <Provider store = { store }>
                    <PersistGate loading = { null } persistor = { persistor }>
                */}

                        {/* Alternative Home/Landing Pages:
                        <Route exact path = "/"       render = { () => ( <Page><Landing /></Page> )} />
                        <Route exact path = "/"       render = { () => ( <Page><Home /></Page> )} />
                        */}

                        {/* New Home Page with hero section and header in 1 component. */}
                        <Route
                            //exact path = "/"
                            exact path = {[ "/", "/index.html" ]}
                            render = { () => (
                                <HomePage
                                    isHomePage = { true }
                                    userDetails = { userDetails }
                                    //connectWalletFunction = { handleConnectWallet }
                                    //clickConnectWalletFunction = { clickConnectWalletButton }
                                    //chooseWalletType = { chooseWalletType }
                                    //clickSystemWallet = { clickSystemWallet }
                                    //showRegisterDialog = { showRegisterDialog }
                                    //showLoginDialog = { showLoginDialog }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    />
                            )}
                        />

                        {/* Testing responsiveness...
                        <Route exact path = "/responsive"             render = { ()       => ( <Page><Responsive />                         </Page> )} />
                        */}

                        <Route
                            exact path="/forgot-password/:token"
                            //component = { Wrapper(Resetpw) }
                            render = { () => (
                                <Page
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ResetPassword />
                                </Page>
                            )}/>

                        <Route
                            exact path="/forgot-password-dialog/:token"
                            component = { ModalDialogWrapper(ResetPassword, 'ResetPassword') }
                            /*
                            render = { () => (
                                <ModalDialogWrapper>
                                    <ResetPassword />
                                </ModalDialogWrapper>
                            )}
                            */
                            />

                        <Route
                            exact path = "/faqs"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Faqs />
                                </Page>
                            )} />

                        <Route
                            exact path = "/contactus"
                            render = {() => (
                                <Page
                                    //chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ContactUs
                                        userDetails = { userDetails }
                                        />
                                </Page>
                            )} />

                        <Route
                            exact path = "/cancelPayment"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <FailPayment />
                                </Page>
                            )} />

                        <Route
                            exact path = "/my-referral-link"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ReferralLink />
                                </Page>
                            )} /> {/* Renamed from path = "/refer-and-earn" <Page><ReferAndEarn /></Page> */}
                        
                        <Route
                            exact path = "/referred-affiliates"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ReferredAffiliates
                                        chooseWalletType = { chooseWalletType }
                                        userDetails = { userDetails }
                                        />
                                </Page>
                            )} />

                        <Route
                            exact path = "/mywallet"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <MyWallet/>
                                </Page>
                            )} />

                        <Route
                            exact path = "/reward-history"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <RewardHistory />
                                </Page>
                            )} />

                        <Route
                            exact path = "/upload-variants"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <UploadVariants />
                                </Page>
                            )} />

                        <Route
                            exact path = "/upload-details"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <UploadDetails />
                                </Page>
                                )} />

                        <Route
                            exact path = "/connect-wallet"
                            render = { (props)  => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ConnectWallet {...props} />
                                </Page>
                            )} />

                        <Route
                            exact path = "/resell/:id"
                            render = { (props)  => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ResellForm {...props} />
                                </Page>
                            )} />

                        <Route
                            exact path = "/edit-profile"
                            render = { (props) => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <EditProfile {...props} />
                                </Page>
                            )} />

                        <Route
                            exact path = "/user-profile"
                            render = { (props)  => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <UserProfile {...props} />
                                </Page>
                            )} />

                        {/*
                        <Route
                            exact path = "/register"
                            render = { (props)  => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Registration {...props} />
                                </Page>
                            )} />
                        */}

                        <Route
                            exact path = "/activity"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Activity />
                                </Page>
                            )} />

                        <Route
                            exact path = "/search/:category"
                            render = { () => (
                                <Page
                                    userDetails = { userDetails }
                                    //chooseWalletType = { chooseWalletType }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Search />
                                </Page>
                            )} />

                        <Route
                            exact path = "/search/:category/:subCategories"
                            render = { () => (
                                <Page
                                    userDetails = { userDetails }
                                    //chooseWalletType = { chooseWalletType }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Search />
                                </Page>
                            )} />

                        <Route
                            exact path = "/sign"
                            render = { () => (
                                <Page
                                    userDetails = { userDetails }
                                    //chooseWalletType = { chooseWalletType }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Web3signature />
                                </Page>
                            )} />

                        <Route
                            exact path = "/search"
                            render = { () => (
                                <Page
                                    userDetails = { userDetails }
                                    //chooseWalletType = { chooseWalletType }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Search />
                                </Page>
                            )} />

                        {/*
                        <Route
                            exact path = "/checkoutReceipt"
                            render = { (props) => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <CheckoutReceipt {...props} />
                                </Page>
                            )} />
                        */}

                        <Route
                            exact path = "/profile"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Profile />
                                </Page>
                            )} />

                        <Route
                            exact path = "/order-details"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <OrderDetails
                                        userDetails = { userDetails }
                                        //getWindowWidth = { getWindowWidth }
                                        //windowWidth = { windowWidth }
                                        />
                                </Page>
                            )} />

                        <Route
                            exact path = "/order-details/:id"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <OrderDetail />
                                </Page>
                            )} />

                        <Route
                            exact path = "/resell-assets"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ResellAssets />
                                    </Page>
                                )} />

                        <Route
                            exact path = "/profile-edit"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ProfileEdit />
                                    </Page>
                                )} />

                        {/*
                        <Route
                            exact path = "/item/:id"
                            render = { () => (
                                <Page><Item /></Page>
                            )} />
                        */}

                        {/*
                         * New Item Route passing the connectWalletFunction function.
                         */}
                        <Route
                            exact
                            path = "/item/:id"
                            render = { () => (
                                <Page
                                    connectWalletFunction = { handleConnectWallet }
                                    clickConnectWalletFunction = { clickConnectWalletButton }
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Item
                                        funFunction = { fun }
                                        connectWalletFunction = { handleConnectWallet }
                                        chooseWalletType = { chooseWalletType }
                                        showCheckoutModal = { showCheckoutModal }
                                        userDetails = { userDetails }
                                        loginRegisterFunctions = { loginRegisterFunctions }
                                        handleAddToCart = { handleAddToCart }
                                        />
                                </Page>
                            )} />

                        <Route
                            exact path = "/item/:id/checkout"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    ><Checkout />
                                </Page>
                            )} />

                        <Route
                            exact path = "/item-acceptance"
                            render = {() => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ItemAcceptance />
                                </Page>
                            )} />

                        <Route
                            exact path = "/about"
                            render = { () => (
                                <Page
                                    //chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <About />
                                </Page>
                            )} />

                        <Route
                            exact path = "/about/victoria-ai"
                            render = { () => (
                                <Page
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <VictoriaAI />
                                </Page>
                            )} />
                        
                        <Route
                            exact path = "/terms-and-conditions"
                            render = {() => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <TermsAndConditions />
                                </Page>
                            )} />

                        <Route
                            exact path = "/privacy-policy"
                            render = {() => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <PrivacyPolicy />
                                </Page>
                            )} />

                        <Route
                            exact path = "/shipping-policy"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ShippingPolicy />
                                </Page>
                            )} />

                        <Route
                            exact path = "/returns-policy"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <ReturnsPolicy />
                                </Page>
                            )} />

                        <Route
                            exact path = "/item/view/:id"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Item mode={"view"} />
                                </Page>
                            )} />

                        {/* Renamed UserItem -> Item
                        <Route exact path = "/item"                   render = { ()       => ( <Page><UserItem />                     </Page> )} />
                        */}

                        <Route
                            exact path = "/my-assets"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    //disconnectUserFunction = { disconnectUser }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <MyAssets
                                        disconnectUserFunction = { disconnectUser }
                                        handleAddToCart = { handleAddToCart }
                                        />
                                </Page>
                            )}
                            />

                        <Route
                            exact path = "/pagelist"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <PageList />
                                </Page>
                            )} />

                        <Route
                            exact path = "/auctions"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Auctions />
                                </Page>
                            )} />

                        {/*<Route exact path = "/threeDee"               render = { ()       => ( <Page chooseWalletType = { chooseWalletType } userDetails = { userDetails }><ThreeDee /></Page> )} />*/}

                        <Route
                            exact path = "/checkout" 
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    refreshUser = { getProfileDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <CartCheckout
                                        refreshUser = { getProfileDetails }
                                        userDetails = { userDetails }
                                        />
                                </Page>
                            )} />

                        <Route
                            exact path = "/cart"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Cart
                                        userDetails = { userDetails }
                                        />
                                </Page>
                            )} />

                        <Route
                            exact path = "/notifications"
                            render = { () => (
                                <Page
                                    chooseWalletType = { chooseWalletType }
                                    userDetails = { userDetails }
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Notifications />
                                </Page>
                            )} />

                        {/* Available but unused paths...
                        <Route
                            exact path = "/faq"
                            render={() => (
                                <Page
                                    loginRegisterFunctions = { loginRegisterFunctions }
                                    cartCount = { cartCount }
                                    >
                                    <Faq />
                                </Page>
                            )} />

                        <Route
                            exact path = "/threeDeeViewer/:id"
                            render = { () => ( <ThreeDeeViewer /> )} />
                        <Route exact path = "/search/:category/:subCategories/:subSubCategories" render={() => ( <Page><Search /></Page> )} />
                        <Route exact path = "/AboutSantaCasa"         render = { ()       => ( <Page><AboutSantaCasa /></Page> )} />
                        */}

                        {/* <Route component={NoMatch} /> */}

                {/*
                    </PersistGate>
                </Provider>
                */}

            </Switch>
        {/*
        </Router>
        */}

        </>
    );
}

export default App;
